<template>
  <div class="news-wrapper">
    <CloseButton @click="$emit('closePopUp')" />
    <div class="news-content" v-if="newsData">
      <img
        class="popup-image"
        :src="newsData.image.url"
        :alt="newsData.image.alt"
        :srcset="createSrcSet(newsData.image.sizes)"
        sizes="500px"
      />
      <h3>{{ newsData.titre }}</h3>
      <p>{{ newsData.description }}</p>
      <a
        :href="correctedUrl"
        :target="newsData.cta_url.includes('panipro.ch/') ? '_self' : '_blank'"
        rel="noopener noreferrer"
        v-if="newsData.cta"
      >
        <p-button :label="newsData.cta" />
      </a>
    </div>
  </div>
  <div class="popup-background" v-if="newsData" @click="$emit('closePopUp')" />
</template>

<script>
import PButton from "../atoms/pButton";
import allMixins from "../../assets/mixins/allMixins";
import CloseButton from "../atoms/CloseButton";
export default {
  name: "NewsPopUp",
  components: { CloseButton, PButton },
  emits: ["closePopUp"],
  mixins: [allMixins],
  props: {
    newsData: Object,
  },
  mounted() {
    document.cookie = `latestNews=${this.newsData.titre}`;
  },
  computed: {
    correctedUrl() {
      const correctedUrl = this.newsData.cta_url;
      if (correctedUrl.includes("panipro.ch")) {
        // if panipro, redirect to current origin regardless of what panipro entered in wp field
        const parts = correctedUrl.split("panipro.ch");
        return window.location.origin + parts[1];
      } else {
        return correctedUrl;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.news-wrapper {
  z-index: 9;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);

  .news-content {
    width: 30vw;
    background-color: white;
    border-radius: 16px;
    padding: 4.5rem;
    max-height: 95vh;
    overflow-y: auto;

    .popup-image {
      width: 100%;
      max-height: 60vh;
      object-fit: contain;
    }

    h3 {
      margin-top: 2.5rem;
    }

    button {
      margin-top: 2.5rem;
    }
  }
}

@media (max-width: 1200px) {
  .news-wrapper {
    .news-content {
      width: 50vw;
    }
  }
}
@media (max-width: $small-screen) {
  .news-wrapper {
    .news-content {
      width: 75vw;
    }
  }
}

@media (max-width: $xs-screen) {
  .news-wrapper {
    .news-content {
      width: 90vw;
    }
  }
}

.popup-background {
  z-index: 8;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
  transition: all 0.5s linear;
}
</style>
