<template>
  <div class="close-btn-container">
    <img
      src="../../assets/icons/close.svg"
      alt="close contact form"
      @click="closeContactForm"
    />
  </div>
</template>

<script>
export default {
  name: "CloseButton",
};
</script>

<style scoped lang="scss">
.close-btn-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 64px;
  height: 64px;
  transform: translate(50%, -50%);
  z-index: 10;
  img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
</style>
