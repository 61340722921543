<template>
  <teleport to="head">
    <title>{{ data.meta_title }}</title>
    <meta name="description" :content="data.meta_description" />
  </teleport>

  <div class="main-grid-container">
    <section class="introduction-container">
      <h1>{{ data.titre_principal }}</h1>
      <p>{{ data.sous_titre }}</p>
      <a @click="openContact(4)">{{ data.action_demo }} 🠖</a>
    </section>
    <img
      class="main-image"
      v-if="data.image_principale"
      :src="data.image_principale.url"
      :srcset="createSrcSet(data.image_principale.sizes)"
      :alt="data.image_principale_alt"
      sizes="100vw"
    />
    <div class="img-overlay"></div>

    <h2 class="nos-valeurs-title">{{ data.titre_valeurs }}</h2>
    <div class="valeurs">
      <div v-if="data.valeur_1">
        <p style="font-weight: bold">{{ data.valeur_1.split(";")[0] }}</p>
        <p>{{ data.valeur_1.split(";")[1] }}</p>
      </div>
      <div v-if="data.valeur_2">
        <p style="font-weight: bold">{{ data.valeur_2.split(";")[0] }}</p>
        <p>{{ data.valeur_2.split(";")[1] }}</p>
      </div>
      <div v-if="data.valeur_3">
        <p style="font-weight: bold">{{ data.valeur_3.split(";")[0] }}</p>
        <p>{{ data.valeur_3.split(";")[1] }}</p>
      </div>
      <div v-if="data.valeur_4">
        <p style="font-weight: bold">{{ data.valeur_4.split(";")[0] }}</p>
        <p>{{ data.valeur_4.split(";")[1] }}</p>
      </div>
    </div>
  </div>

  <div class="main-grid-container company-images">
    <img
      v-if="data.image_1"
      class="img-2-5"
      :src="data.image_1.url"
      :alt="data.images_entreprise_alt"
      :srcset="createSrcSet(data.image_1.sizes)"
      sizes="(max-width: 1440px) 42vw, 600px"
    />
    <div class="img-flex-7-5">
      <img
        v-if="data.image_2"
        :src="data.image_2.url"
        :alt="data.images_entreprise_alt"
        :srcset="createSrcSet(data.image_2.sizes)"
        sizes="(max-width: 1440px) 42vw, 600px"
      />
      <img
        v-if="data.image_3"
        class="flex-small"
        :src="data.image_3.url"
        :alt="data.images_entreprise_alt"
        :srcset="createSrcSet(data.image_3.sizes)"
        sizes="(max-width: 1440px) 42vw, 600px"
      />
    </div>
    <div class="img-flex-2-5">
      <img
        v-if="data.image_4"
        class="flex-small"
        :src="data.image_4.url"
        :alt="data.images_entreprise_alt"
        :srcset="createSrcSet(data.image_4.sizes)"
        sizes="(max-width: 1440px) 42vw, 600px"
      />
      <img
        v-if="data.image_5"
        :src="data.image_5.url"
        :alt="data.images_entreprise_alt"
        :srcset="createSrcSet(data.image_5.sizes)"
        sizes="(max-width: 1440px) 42vw, 600px"
      />
    </div>
    <img
      v-if="data.image_6"
      class="img-7-5"
      :src="data.image_6.url"
      :alt="data.images_entreprise_alt"
      :srcset="createSrcSet(data.image_6.sizes)"
      sizes="(max-width: 1440px) 42vw, 600px"
    />
  </div>
  <RetourVersLogiciels
    class="retour-logiciels"
    v-if="data.titre_logiciels"
    :titre-logiciel="data.titre_logiciels"
    :description_foodsystem="data.description_foodsystem"
    :description_winwin="data.description_winwin"
    :action_foodsystem="data.action_foodsystem"
    :action_winwin="data.action_winwin"
    :image_logiciels="data.image_logiciels"
    :image_logiciels_alt="data.image_logiciels_alt"
  />

  <RestonsEnContact
    class="restons-contact"
    :titre_newsletter="data.titre_newsletter"
    :description_newsletter="data.description_newsletter"
    :placeholder_email="data.placeholder_email"
    :action_newsletter="data.action_newsletter"
    :image_newsletter="data.image_newsletter"
    :image_newsletter_alt="data.image_newsletter_alt"
    :bad_email="data.bad_email"
    :success="data.success"
    :error="data.error"
  />
</template>

<script>
import { mapState } from "vuex";
import allMixins from "../assets/mixins/allMixins";
import RetourVersLogiciels from "../components/molecules/RetourVersLogiciels";
import RestonsEnContact from "../components/molecules/RestonsEnContact";

export default {
  name: "Home",
  components: { RestonsEnContact, RetourVersLogiciels },
  mixins: [allMixins],
  created() {
    this.loadContentForCurrentLanguage();
  },
  methods: {
    loadContentForCurrentLanguage() {
      fetch(
        `${this.fetchlink}/wp-json/wp/v2/pages?slug=entreprise-${this.$store.state.language}&_fields[]=acf`
      )
        .then((r) => r.json())
        .then((r) => (this.data = r[0].acf));
    },
  },
  data() {
    return {
      data: [],
    };
  },
  computed: mapState(["language"]),
  watch: {
    language() {
      this.loadContentForCurrentLanguage();
    },
  },
};
</script>

<style scoped lang="scss">
.main-grid-container {
  margin-top: $header-margin-top;
  @include twelve-col-grid;
}

.introduction-container {
  grid-column: col-start 3 / span 8;
  text-align: center;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  * {
    color: white;
  }

  p {
    margin-top: 2.5vmin;
    margin-bottom: 5vmin;
    padding: 0 5rem;
  }

  a {
    &:hover {
      cursor: pointer;
    }
  }
}

@mixin main-image-positioning {
  grid-column: col-start 1 / span 12;
  grid-row: 1;
  z-index: -2;
  height: 55vmin;
  width: 100vw;
  object-fit: cover;
  margin-left: $side-img;
}

.main-image {
  @include main-image-positioning;
}

.img-overlay {
  @include main-image-positioning;
  background-color: rgba(239, 123, 17, 0.2);
}

.nos-valeurs-title {
  grid-column: col-start 1 / span 12;
  text-align: center;
  margin: 7vmin 0;
}

.valeurs {
  grid-column: col-start 2 / span 10;
  display: flex;
  justify-content: space-between;

  div {
    width: 25%;
    text-align: center;
    flex-grow: 1;
  }

  p {
    padding: 0 2vw;
  }
}

.img-2-5 {
  grid-column: col-start 2 / span 5;
  width: 100%;
  aspect-ratio: 1.6;
  object-fit: cover;
}

.img-flex-7-5 {
  grid-column: col-start 7 / span 5;
  align-self: end;
  display: flex;
  justify-content: space-between;
  aspect-ratio: 1.6;

  img {
    width: 49%;
    object-fit: cover;
  }

  img.flex-small {
    height: 80%;
    object-fit: cover;
    align-self: flex-end;
  }
}

.img-flex-2-5 {
  grid-column: col-start 2 / span 5;
  display: flex;
  justify-content: space-between;
  aspect-ratio: 1.6;

  img {
    width: 49%;
    object-fit: cover;
  }

  img.flex-small {
    height: 80%;
    object-fit: cover;
  }
}

.img-7-5 {
  grid-column: col-start 7 / span 5;
  width: 100%;
  aspect-ratio: 1.6;
  object-fit: cover;
}

.retour-logiciels {
  margin-top: 13vmin;
}

.restons-contact {
  margin-top: 13vmin;
  margin-bottom: 8vmin;
}

@media (max-width: $small-screen) {
  .introduction-container {
    grid-column: col-start 2 / span 10;
  }

  .valeurs {
    flex-wrap: wrap;

    div {
      width: 45%;
    }
  }

  .img-2-5 {
    grid-column: col-start 1 / span 6;
  }
  .img-flex-7-5 {
    grid-column: col-start 7 / span 6;
  }
  .img-flex-2-5 {
    grid-column: col-start 1 / span 6;
  }
  .img-7-5 {
    grid-column: col-start 7 / span 6;
  }
}

@media (max-width: $xs-screen) {
  .introduction-container {
    grid-column: col-start 1 / span 12;
    justify-content: space-evenly;

    p {
      display: none;
    }
  }

  .valeurs {
    grid-column: col-start 1 / span 12;

    p {
      padding: 0 1vw;
    }
  }

  .company-images {
    grid-row-gap: 1rem;
  }
  .img-2-5 {
    grid-column: col-start 2 / span 10;
    grid-row: 2;
  }
  .img-flex-7-5 {
    grid-column: col-start 2 / span 10;
  }
  .img-flex-2-5 {
    grid-column: col-start 2 / span 10;
  }
  .img-7-5 {
    grid-column: col-start 2 / span 10;
    grid-row: 3;
  }
}
</style>
