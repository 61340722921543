import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueGtm from "vue-gtm";

const app = createApp(App);

// app.config.globalProperties.fetchlink = "http://localhost"; // DEV
// app.config.globalProperties.fetchlink = "https://panipro.raccoon.ch/wp"; // DEV LIVE
app.config.globalProperties.fetchlink = "https://panipro.ch/wp"; // PROD

app
  .use(store)
  .use(router)
  .use(VueGtm, {
    id: "GTM-W2P7TZK",
    defer: false,
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false,
  })
  .mount("#app");
