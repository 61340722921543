import { createStore } from "vuex";

const storeAcceptedLanguages = ["fr", "de", "it", "en"];
const navigatorLanguage = navigator.language;
const localStorageLanguage = localStorage.getItem("language");

export default createStore({
  state: {
    acceptedLanguages: storeAcceptedLanguages,
    language:
      localStorageLanguage ||
      (storeAcceptedLanguages.includes(navigatorLanguage)
        ? navigatorLanguage
        : "fr"),
    showContact: false,
    contactStartAtStep: 1,
  },
  mutations: {
    changeLanguage(state, newLanguage) {
      if (state.acceptedLanguages.includes(newLanguage)) {
        state.language = newLanguage;
        localStorage.setItem("language", newLanguage);
      }
    },
    showContact(state, payload) {
      state.showContact = payload.show;
      if (payload.show === false) {
        state.contactStartAtStep = 1;
      }
      if (payload.startStep) {
        state.contactStartAtStep = payload.startStep;
      }
    },
  },
  actions: {},
  modules: {},
});
