<template>
  <div class="container">
    <img src="../../assets/icons/vu.svg" alt="checked icon" />
    <span>{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: "ProductOption",
  props: {
    label: String,
  },
};
</script>

<style scoped lang="scss">
.container {
  display: flex;
  justify-items: center;
  align-items: center;

  span {
    margin-left: 2rem;
  }
}
</style>
