<template>
  <teleport to="head"></teleport>
  <ContactPanel :data="contactData" />
  <Header :data="headerData" />
  <router-view>
    <router-view v-slot="{ Component }">
      <keep-alive exclude="Article">
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </router-view>
  <Footer :data="footerData" />
  <NewsPopUp
    :news-data="newsData"
    v-if="showNews"
    @closePopUp="deactivateNews"
  />
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
import { mapState } from "vuex";
import ContactPanel from "./components/ContactPanel";
import NewsPopUp from "./components/molecules/NewsPopUp";

export default {
  name: "App",
  components: { NewsPopUp, ContactPanel, Footer, Header },
  created() {
    this.loadContentForCurrentLanguage();
  },
  methods: {
    loadContentForCurrentLanguage() {
      const html = document.documentElement;
      html.setAttribute("lang", this.language);
      const lang = this.$store.state.language;
      fetch(`${this.fetchlink}/wp-json/rac/v1/hfcn?lang=${lang}`)
        .then((r) => r.json())
        .then((r) => {
          this.headerData = r.header[0].acf;
          this.footerData = r.footer[0].acf;
          this.contactData = r.contact[0].acf;
          this.newsData = r.news.acf;
          this.showNews = this.determineIfShowNews();
        });
    },
    determineIfShowNews() {
      try {
        const dateStart = this.parseMyDate(this.newsData.date_debut);
        const dateEnd = this.parseMyDate(this.newsData.date_fin);
        const currentDate = Date.now();

        const cookies = {};
        document.cookie.split(";").forEach((element) => {
          const [key, value] = element.split("=");
          cookies[key.trim()] = value;
        });
        const latestNewsSeen = cookies.latestNews;
        const newsAlreadySeen = latestNewsSeen === this.newsData?.titre;

        return (
          this.newsData.active &&
          !newsAlreadySeen &&
          currentDate >= dateStart &&
          currentDate <= dateEnd
        );
      } catch (e) {
        return false;
      }
    },
    deactivateNews() {
      this.showNews = false;
      document.cookie = `latestNews=${this.newsData.titre}`;
    },
    parseMyDate(date) {
      const parsed = Date.parse(date);
      if (!isNaN(parsed)) {
        return parsed;
      }
      // date parsing for safari
      return Date.parse(date.replace(/-/g, "/").replace(/[a-z]+/gi, " "));
    },
  },
  data() {
    return {
      headerData: [],
      footerData: [],
      contactData: [],
      newsData: null,
      showNews: false,
      showDot: false,
    };
  },
  computed: mapState(["language"]),
  watch: {
    language() {
      this.loadContentForCurrentLanguage();
    },
  },
};
</script>

<style lang="scss">
.rounded {
  position: absolute;
  left: 0;
  bottom: 0;
  color: lightgrey;
  font-size: 12px;
}
@import "./assets/styles/cssNormalize.css";
@import "assets/styles/fonts";
@import "assets/styles/basestyles";
//colors & layout imported via vue.config.js (repeated in every component)
</style>
