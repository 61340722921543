<template>
  <div v-if="show" class="loader"></div>
</template>

<script>
export default {
  name: "Spinner",
  props: {
    show: Boolean,
  },
};
</script>

<style scoped lang="scss">
div {
  margin: auto auto;
}

.loader {
  border: 0.5rem solid #f3f3f3;
  border-top: 0.5rem solid $orange;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
