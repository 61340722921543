<template>
  <div class="main-container" :class="{ hide: !$store.state.showContact }">
    <CloseButton @click="closeContactForm" />
    <div class="positioning">
      <div class="flex" v-if="currentStep === steps.start">
        <h3>{{ data.titre_aide }}</h3>
        <pButton
          class="p2 main-btn"
          :label="data.action_demo"
          @click="trackDemo"
        />
        <pButton
          class="p2 main-btn"
          :label="data.action_support"
          @click="trackSupport"
        />
        <pButton
          class="p2 main-btn"
          :label="data.action_autre"
          @click="trackContact"
        />
      </div>

      <div class="flex" v-if="currentStep === steps.autre">
        <h3>{{ data.titre_autre_demande }}</h3>
        <p class="cp-text">{{ data.sous_titre_autre_demande }}</p>
        <p class="orange bold link-margin">{{ data.email_autre_demande }}</p>
        <button class="action-btn" @click="currentStep = steps.start">
          {{ data.retour }}
        </button>
      </div>

      <div class="flex" v-if="currentStep === steps.support">
        <h3>{{ data.titre_support }}</h3>
        <p class="cp-text">{{ data.sous_titre_support }}</p>
        <p class="orange bold link-margin">{{ data.email_support }}</p>
        <p class="cp-text">{{ data.horaires_support }}</p>
        <a class="cp-text" :href="data.lien_teamviewer" download>
          {{ data.text_teamviewer }}</a
        >
        <p class="p3">{{ data.numero_support }}</p>
        <button class="action-btn" @click="currentStep = steps.start">
          {{ data.retour }}
        </button>
      </div>

      <div class="flex" v-if="currentStep === steps.demoProducts">
        <h3>{{ data.sous_titre_demo }}</h3>
        <div class="product-choice">
          <input class="checkbox" type="checkbox" v-model="demo.winwin" />
          <p class="cp-text" @click="demo.winwin = !demo.winwin">
            {{ data.winwin_demo }}
          </p>
        </div>
        <div class="product-choice">
          <input class="checkbox" type="checkbox" v-model="demo.foodsystem" />
          <p class="cp-text" @click="demo.foodsystem = !demo.foodsystem">
            {{ data.foodsystem_demo }}
          </p>
        </div>
        <div class="product-choice">
          <input class="checkbox" type="checkbox" v-model="demo.panipro" />
          <p class="cp-text" @click="demo.panipro = !demo.panipro">
            {{ data.panipro_demo }}
          </p>
        </div>
        <p class="p2 error-label" v-bind:class="{ activeError: showDemoError }">
          {{ data.erreur_choix }}
        </p>
        <div class="action-btn-flex">
          <button class="action-btn" @click="currentStep = steps.start">
            {{ data.retour }}
          </button>
          <button class="action-btn" @click="verifyDemo">
            {{ data.suivant }}
          </button>
        </div>
      </div>

      <div class="flex" v-if="currentStep === steps.demoContact">
        <h3>{{ data.sous_titre_contact }}</h3>
        <input
          class="input-field"
          v-model="demo.nom"
          type="text"
          :placeholder="data.votre_nom + ' *'"
          autocomplete="off"
        />
        <label
          class="p2 error-label"
          v-bind:class="{ activeError: showNameError }"
          >{{ data.erreur_nom }}</label
        >
        <input
          class="input-field"
          v-model="demo.entreprise"
          type="text"
          :placeholder="data.votre_entreprise"
          autocomplete="off"
        />
        <label class="p2 error-label">error</label>
        <input
          class="input-field"
          v-model="demo.numero"
          type="tel"
          :placeholder="data.votre_numero + ' *'"
          autocomplete="off"
          @input="managerNumberErrors"
        />

        <div class="relative-error">
          <label
            class="p2 error-label"
            v-bind:class="{ activeError: showNumberError }"
            >{{ data.erreur_numero }}</label
          >
          <label
            class="p2 error-label"
            v-bind:class="{ activeError: showEmptyNumberAndMail }"
          >
            {{ data.erreur_numero_mail }}</label
          >
        </div>

        <div class="hidemoment" :class="{ showmoment: demo.numero !== '' }">
          <p class="p2 moment-margin">
            {{ data.moment_de_preference }}
          </p>
          <div class="moment-section">
            <input class="checkbox" type="checkbox" v-model="demo.matin" />
            <label class="p2">{{ data.matins }}</label>
            <input class="checkbox" type="checkbox" v-model="demo.midi" />
            <label class="p2">{{ data.midis }}</label>
            <input class="checkbox" type="checkbox" v-model="demo.apresmidi" />
            <label class="p2">{{ data.apres_midis }}</label>
          </div>
        </div>

        <input
          class="input-field"
          v-model="demo.email"
          type="email"
          :placeholder="data.votre_mail + ' *'"
          autocomplete="off"
          @input="manageEmailErrors"
        />
        <div class="relative-error">
          <label
            class="p2 error-label"
            v-bind:class="{ activeError: showEmailError }"
            >{{ data.erreur_email }}</label
          >
          <label
            class="p2 error-label"
            v-bind:class="{ activeError: showEmptyNumberAndMail }"
          >
            {{ data.erreur_numero_mail }}</label
          >
        </div>

        <div class="action-btn-flex">
          <button class="action-btn" @click="currentStep = steps.demoProducts">
            {{ data.retour }}
          </button>
          <button class="action-btn" @click="verifyContact">
            {{ data.suivant }}
          </button>
        </div>
      </div>

      <div class="flex resume" v-if="currentStep === steps.demoSummary">
        <h3>{{ data.resume }}</h3>
        <p class="cp-text">{{ data.sous_titre_resume }}</p>

        <div class="resume-produits">
          <p class="bold cp-text" v-if="demo.winwin">{{ data.winwin_demo }}</p>
          <p class="bold cp-text" v-if="demo.foodsystem">
            {{ data.foodsystem_demo }}
          </p>
          <p class="bold cp-text" v-if="demo.panipro">
            {{ data.panipro_demo }}
          </p>
        </div>
        <p class="cp-text">{{ data.contact_resume }}</p>
        <p class="cp-text">{{ demo.nom }}</p>
        <p class="cp-text" v-if="demo.numero">{{ demo.numero }}</p>
        <p class="cp-text" v-if="demo.email">{{ demo.email }}</p>
        <p class="commentaires-sup cp-text">{{ data.commentaires }}</p>
        <textarea v-model="demo.commentaires" rows="6"></textarea>
        <p class="p2 asterix">{{ data.securite_donnees }}</p>

        <div class="action-btn-flex">
          <button class="action-btn" @click="currentStep = steps.demoContact">
            {{ data.retour }}
          </button>
          <div class="relative-div">
            <button
              class="action-btn"
              :class="{ hidebtn: showSpinner }"
              @click="sendForm"
            >
              {{ data.confirmer }}
            </button>
            <Spinner class="spinner" :show="showSpinner" />
          </div>
        </div>
      </div>

      <div class="flex" v-if="currentStep === steps.demoSuccess">
        <h3>{{ data.titre_demo }}</h3>
        <p class="success-label cp-text">
          {{ data.message_ok }}
        </p>
        <button class="action-btn" @click="closeContactForm">
          {{ data.fermer }}
        </button>
      </div>

      <div class="flex" v-if="currentStep === steps.demoError">
        <h3>{{ data.titre_demo }}</h3>
        <p class="cp-text" style="color: red">{{ data.message_erreur }}</p>
        <p class="orange bold link-margin">{{ data.email_autre_demande }}</p>
        <button class="action-btn" @click="closeContactForm">
          {{ data.fermer }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import pButton from "./atoms/pButton";
import { mapState } from "vuex";
import CloseButton from "./atoms/CloseButton";
import Spinner from "./atoms/Spinner";

export default {
  name: "ContactPanel",
  components: { Spinner, CloseButton, pButton },
  props: {
    data: Object,
  },
  data() {
    return {
      isInactive: true,
      currentStep: 1,
      steps: {
        start: 1,
        autre: 2,
        support: 3,
        demoProducts: 4,
        demoContact: 5,
        demoSummary: 6,
        demoSuccess: 7,
        demoError: 8,
      },
      demo: {
        winwin: false,
        foodsystem: false,
        panipro: false,
        nom: "",
        entreprise: null,
        numero: "",
        matin: false,
        midi: false,
        apresmidi: false,
        email: "",
        commentaires: null,
      },
      postResult: null,
      showDemoError: false,
      showNameError: false,
      showEmptyNumberAndMail: false,
      showNumberError: false,
      showEmailError: false,
      showSpinner: false,
    };
  },
  methods: {
    trackDemo() {
      this.currentStep = this.steps.demoProducts;
    },
    trackSupport() {
      this.currentStep = this.steps.support;
    },
    trackContact() {
      this.currentStep = this.steps.autre;
    },
    verifyDemo() {
      if (this.demo.winwin || this.demo.foodsystem || this.demo.panipro) {
        this.currentStep = this.steps.demoContact;
        this.showDemoError = false;
      } else {
        this.showDemoError = true;
      }
    },
    managerNumberErrors() {
      this.showEmptyNumberAndMail = false;
      if (this.showNumberError) {
        this.showNumberError = this.verifyNumber();
      }
    },
    manageEmailErrors() {
      this.showEmptyNumberAndMail = false;
      if (this.showEmailError) {
        this.showEmailError = this.verifyEmail();
      }
    },
    verifyNumber() {
      const phoneRegex = /(^\+|^0{2})\d{11}$|^0\d{9}$/;
      const noSpaceNumber = this.demo.numero.replace(/\s/g, "");
      return this.demo.numero.length > 0 && !phoneRegex.test(noSpaceNumber);
    },
    verifyEmail() {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return this.demo.email.length > 0 && !emailRegex.test(this.demo.email);
    },
    verifyContact() {
      this.clearErrors();
      if (this.demo.nom.trim().length <= 0) {
        this.showNameError = true;
      }
      if (this.demo.numero.length <= 0 && this.demo.email.length <= 0) {
        this.showEmptyNumberAndMail = true;
        return;
      }

      if (this.verifyNumber()) {
        this.showNumberError = true;
      }
      if (this.verifyEmail()) {
        this.showEmailError = true;
      }

      if (
        !this.showNameError &&
        !this.showEmptyNumberAndMail &&
        !this.showNumberError &&
        !this.showEmailError
      ) {
        this.currentStep = this.steps.demoSummary;
      }
    },
    clearErrors() {
      this.showNameError =
        this.showEmptyNumberAndMail =
        this.showNumberError =
        this.showEmailError =
        this.showDemoError =
          false;
    },
    sendForm() {
      this.showSpinner = true;
      this.demo.langue = this.$store.state.language;
      fetch(`${this.fetchlink}/wp-json/v1/contact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.demo),
      })
        .then((r) => r.json())
        .then((r) => {
          this.showSpinner = false;
          this.currentStep =
            r === true ? this.steps.demoSuccess : this.steps.demoError;
        })
        .catch(() => {
          this.showSpinner = false;
          this.currentStep = this.steps.demoError;
        });
    },
    closeContactForm() {
      this.$store.commit("showContact", { show: false, startStep: 1 });
      // clear form after 0.7 second (css transition duration to get out of screen)
      setTimeout(() => {
        this.currentStep = 1;
        this.demo.winwin = this.demo.foodsystem = this.demo.panipro = false;
        this.clearErrors();
      }, 700);
    },
  },
  computed: mapState(["contactStartAtStep"]),
  watch: {
    contactStartAtStep() {
      this.currentStep = this.$store.state.contactStartAtStep;
    },
  },
};
</script>

<style scoped lang="scss">
.hide {
  right: min(-35vw, -400px) !important;
  transition: 0.7s;
}

* {
  white-space: pre-wrap;
}

.main-container {
  position: fixed;
  top: 20vh;
  right: calc(1vw + 16px);
  transition: 0.7s;
  z-index: 5;
}

$scrollbarwidth: 0.6rem;

.positioning {
  overflow-y: scroll;
  overflow-x: visible;
  max-height: 80vh;
  z-index: 8;
  background-color: white;
  box-shadow: $boxshadow;

  &::-webkit-scrollbar {
    width: $scrollbarwidth;
    background-color: white;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $orange;
  }

  .flex {
    display: flex;
    flex-direction: column;
    width: 350px;
    align-items: center;
    text-align: center;
    padding: 2.4rem;

    .input-field {
      @include input-field;
    }

    a {
      margin-top: 1.6rem;
      text-decoration: underline;
    }
  }
}

.error-label {
  visibility: hidden;
  color: red;
  align-self: start;
}

.activeError {
  visibility: visible;
}

.main-btn {
  width: 50%;
  padding: 1rem 0;
  margin-top: 10px !important;

  &:last-child {
    margin-bottom: 2.4rem;
  }
}

.action-btn-flex {
  width: 100%;
  margin-top: 2.4rem;
  display: flex;
  justify-content: space-between;
}

.action-btn {
  border: 1px solid transparent;
  border-radius: 6px;
  background-color: transparent;
  cursor: pointer;
  color: $orange;
  font-size: 14px;

  &:hover {
    border: 1px solid $orange;
  }
}

.hidemoment {
  max-height: 0;
  overflow-y: hidden;
  transition: max-height 1s;
}

.showmoment {
  max-height: 100px;
  transition: max-height 1s;
}

.moment-margin {
  margin-bottom: 1.5rem;
}

.moment-section {
  display: flex;
  margin-bottom: 1rem;

  .checkbox {
    margin-right: 1rem;
  }

  label {
    margin-right: 1rem;
  }
}

.relative-error {
  position: relative;
  width: 100%;
  text-align: start;

  label:last-child {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.checkbox {
  margin-right: 2rem;
  min-width: 2.4rem;
  height: 2.4rem;
  appearance: none;
  border: 2px solid lightgray;
  border-radius: 2px;
  transition-duration: 0.3s;
  background-color: white;
  cursor: pointer;

  &:checked {
    background-color: $orange;
  }
}

.product-choice {
  align-self: flex-start;
  text-align: start;
  margin: 1rem 1.2rem;
  display: flex;
  align-items: center;

  p {
    display: inline;
    cursor: pointer;
  }
}

h3 {
  margin-bottom: 1.6rem;
  font-size: 20px;
}

p {
  margin: 0 0;
}

.link-margin {
  margin: 3.2rem 0;
}

.action .p2 {
  margin: 0 0;
}

.p3 {
  margin: 1.6rem 0;
}

.bold {
  font-weight: bold;
}

.resume {
  .resume-produits {
    margin: 2rem 0;
  }

  .commentaires-sup {
    margin-top: 2.4rem;
    margin-bottom: 1rem;
    align-self: start;
    color: lightgray;
  }

  .asterix {
    margin-top: 1rem;
    align-self: start;
    text-align: start;
    line-height: clamp(1.2rem, 1rem + 0.521vmax, 2.4rem); //12, 24@1920, 32
    color: grey;
  }

  textarea {
    resize: none;
    border: none;
    background-color: #f0f0f0;
    width: 100%;
    padding: 1.6rem;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 2px solid black;

    &:focus {
      outline: none;
      border-bottom: 2px solid $orange;
    }
  }
}

.success-label {
  margin-top: 1.6rem;
  margin-bottom: 3.2rem;
}

.hidebtn {
  visibility: hidden;
}

.relative-div {
  position: relative;
}

.spinner {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1.5rem;
}

@media (max-width: $xs-screen) {
  .main-container {
    //32px = close button width
    max-width: calc(100vw - 35px);
    right: 50%;
    transform: translateX(50%);
  }

  .positioning {
    .flex {
      max-width: calc(100vw - 32px - 1rem);
      padding-left: calc(1.8rem + #{$scrollbarwidth});
      padding-right: 1.8rem;
    }
  }
}
</style>
