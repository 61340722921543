<template>
  <div class="footer-grid">
    <section class="logo-section">
      <img
        class="main-icon"
        src="../assets/logos/panipro.svg"
        alt="logo panipro"
      />
      <p class="p2 address">{{ data.adresse_bureau }}</p>
      <div class="socials">
        <a :href="data.lknd_url" target="_blank" rel="noopener noreferrer">
          <img src="../assets/icons/linkedin_icon.svg" alt="Linkedin icon" />
        </a>
        <a
          class="yt_icon"
          :href="data.yt_url"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="../assets/icons/youtube_icon.svg" alt="Youtube icon" />
        </a>
        <a
          class="fb_icon"
          :href="data.fb_url"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="../assets/icons/facebook_icon.svg" alt="Facebook icon" />
        </a>
      </div>
    </section>
    <section class="entreprise-section">
      <h3>{{ data.section_entreprise }}</h3>
      <p class="p2 link" @click="navigateTo('accueil', '#Produits')">
        {{ data.produits }}
      </p>

      <p class="p2 link" @click="navigateTo('entreprise')">
        {{ data.entreprise }}
      </p>

      <p class="p2 link" @click="navigateTo('accueil', '#temoignage')">
        {{ data.temoignages }}
      </p>
      <p class="p2 link" @click="openContact(1)">{{ data.contact }}</p>
    </section>
    <section class="support-section">
      <h3>{{ data.section_support }}</h3>
      <p class="p2 link" @click="openContact(3)">{{ data.depannage }}</p>
      <p class="p2 link" @click="openContact(4)">{{ data.demonstration }}</p>
    </section>
    <section class="newsletter-section">
      <h3>{{ data.section_newsletter }}</h3>
      <form id="newsletter" @submit="checkForm">
        <div class="email-flex-box">
          <input
            class="p2"
            v-model="email"
            type="text"
            id="email"
            name="email"
            :placeholder="data.adresse_email"
            autocomplete="off"
          />
          <img
            :class="{ animateimg: showAnimation }"
            src="../assets/icons/newsletter.svg"
            alt="send newsletter"
            @click.prevent="sendNewsletter"
          />
        </div>
      </form>
      <span
        class="p2 error-label"
        v-bind:class="{ hideError: !showEmailError }"
      >
        {{ data.erreur_email }}
      </span>
      <span
        class="p2 mail-label"
        v-bind:class="{ hideMailSent: !showMailSent }"
      >
        {{ data.email_envoye }}
      </span>
      <span
        class="p2 error-label"
        v-bind:class="{ hideError: !showMailNotSent }"
      >
        {{ data.erreur }}
      </span>

      <img
        class="swissMadeLogo"
        src="../assets/logos/swissmade.png"
        alt="swiss made softare logo"
      />
    </section>
    <div class="bottom-bar">
      <a class="p2 link" :href="data.politique_de_confidentialite_link">{{
        data.politique_de_confidentialite
      }}</a> | 
      <a class="p2 link" :href="data.mentions_legales_link">{{
        data.mentions_legales
      }}</a>
    </div>
  </div>
</template>

<script>
import allMixins from "../assets/mixins/allMixins";

export default {
  name: "Footer",
  mixins: [allMixins],
  props: {
    data: Object,
  },
  data() {
    return {
      email: null,
      showEmailError: false,
      showMailSent: false,
      showMailNotSent: false,
      showAnimation: false,
      postURL: "http://localhost/wp-json/v1/newsletter",
      postResult: null,
    };
  },
  computed: {
    currentLanguage() {
      return this.$store.state.language;
    },
  },
  methods: {
    sendNewsletter() {
      this.showMailSent = false;
      this.showEmailError = false;
      this.showMailNotSent = false;

      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const emailIsValid = emailRegex.test(this.email);
      this.showEmailError = !emailIsValid;

      if (emailIsValid) {
        this.showAnimation = true;
        fetch(`${this.fetchlink}/wp-json/v1/newsletter`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: this.email,
            language: this.currentLanguage,
          }),
        })
          .then((r) => {
            this.showAnimation = false;
            if (r.status === 200) {
              this.showMailSent = true;
              this.email = "";
            } else {
              this.showMailNotSent = true;
            }
          })
          .catch(() => {
            this.showAnimation = false;
            this.showMailNotSent = true;
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.footer-grid {
  @include twelve-col-grid;
  padding-top: 5vh;
  padding-bottom: 8vh;
  background-color: black;

  * {
    color: white;
  }
}

.bottom-bar {
  grid-column: col-start 2 / span 10;
  text-align: center;
}

.logo-section {
  grid-column: col-start 2 / span 2;
  white-space: pre-wrap;

  .main-icon {
    margin-bottom: 2vmin;
  }

  .socials {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2.5rem;

    .yt_icon,
    .fb_icon {
      margin-top: 2px;
    }
  }
}

.entreprise-section {
  margin-top: 2vmin;
  grid-column: col-start 6 / span 2;
}

.support-section {
  margin-top: 2vmin;
  grid-column: col-start 8 / span 2;
}

.newsletter-section {
  margin-top: 2vmin;
  grid-column: col-start 10 / span 2;
  position: relative;

  .swissMadeLogo {
    width: 100%;
    padding-bottom: 1.4rem;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

h3 {
  margin-bottom: 2.5vmin;
}

.email-flex-box {
  display: flex;
  flex-direction: row;
  border: 1px solid grey;
  border-radius: 6px;
  background-color: grey;
  padding: 5px 5px;

  input {
    flex-grow: 2;
    border: none;
    background-color: grey;
    width: 100%;

    &::placeholder {
      color: white;
    }

    &:hover {
      cursor: default;
    }

    &:focus {
      outline: none;
    }
  }

  img {
    padding: 5px;
    cursor: pointer;
  }

  .animateimg {
    animation: animm 1s linear infinite;
  }

  @keyframes animm {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.1;
    }

    100% {
      opacity: 1;
    }
  }
}

.link {
  cursor: pointer;
}

.error-label {
  color: red;
}

.mail-label {
  color: green;
}

.hideError,
.hideMailSent {
  display: none;
}

@media (max-width: $small-screen) {
  .logo-section {
    grid-column: col-start 2 / span 6;
    grid-row: 1;

    .main-icon {
      width: 50%;
    }
  }

  .entreprise-section {
    grid-column: col-start 2 / span 4;
    grid-row: 2;
  }

  .support-section {
    grid-column: span 6;
    grid-row: 2;
  }

  .newsletter-section {
    grid-column: col-start 2 / span 12;
    grid-row: 3;

    .email-flex-box {
      max-width: 50vmin;
    }

    .swissMadeLogo {
      width: 50vmin;
      padding-bottom: 0;
      padding-top: 3rem;
      position: static;
    }
  }
}

@media (max-width: $xs-screen) {
  .logo-section {
    .main-icon {
      width: 100%;
    }
  }
}
</style>
