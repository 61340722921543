<template>
  <section class="temoignage-container">
    <div class="grid-container">
      <div
        class="testimony"
        v-for="(testimony, index) in testimonies"
        v-bind:key="index"
        :class="{ active: index === activeTestimony }"
        @touchstart="onTouchDown"
        @mousedown.prevent="onMouseDown"
      >
        <div class="relative">
          <img
            class="testimony-icon"
            src="../../assets/icons/testimony.svg"
            alt="testimony iconssss"
          />
          <p>{{ testimony.description }}</p>
          <img
            class="testimony-icon-2"
            src="../../assets/icons/testimony.svg"
            alt="testimony icons"
          />
        </div>

        <div
          v-if="testimonies[activeTestimony].avatar"
          id="vignette"
          :style="{
            backgroundImage:
              'url(\'' +
              testimonies[activeTestimony].avatar.sizes.medium +
              '\')',
          }"
        />

        <p class="orange" id="name">{{ testimony.nom }}</p>
        <p id="role">{{ testimony.role }}</p>
      </div>
    </div>
    <span
      v-for="testimonyIndex in testimonies.length"
      v-bind:key="testimonyIndex"
      class="dot"
      :class="{ activeDot: testimonyIndex - 1 === activeTestimony }"
      @click="activateTestimony(testimonyIndex - 1)"
    />
  </section>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Testimonies",
  created() {
    this.loadContentForCurrentLanguage();
  },
  methods: {
    loadContentForCurrentLanguage() {
      fetch(
        `${this.fetchlink}/wp-json/wp/v2/testimony?lang=${this.$store.state.language}`
      )
        .then((r) => r.json())
        .then((r) => (this.testimonies = r.map((i) => i.acf)));
    },
    activateTestimony(index) {
      if (index < this.testimonies.length) {
        this.activeTestimony = index;
      }
    },
    onTouchDown(e) {
      this.originX = e.touches[0].clientX;
      window.addEventListener("touchmove", this.onTouchMove);
      window.addEventListener("touchend", this.onTouchUp);
    },
    onTouchMove(e) {
      this.newX = e.touches[0].clientX;
    },
    onTouchUp() {
      window.removeEventListener("touchmove", this.onTouchMove);
      window.removeEventListener("touchend", this.onTouchUp);
      this.calculateDragAndChangeTestimony();
    },
    onMouseDown(e) {
      this.originX = e.x;
      window.addEventListener("mouseup", this.onMouseUp);
    },
    onMouseUp(e) {
      this.newX = e.x;
      window.removeEventListener("mouseup", this.onMouseUp);
      this.calculateDragAndChangeTestimony();
    },
    calculateDragAndChangeTestimony() {
      const movement = this.originX - this.newX;
      if (movement < -100 || movement < -(window.innerWidth * 0.15)) {
        let newIndex = this.activeTestimony - 1;
        if (newIndex < 0) newIndex = this.testimonies.length - 1;
        this.activeTestimony = newIndex;
      } else if (movement > 100 || movement > window.innerWidth * 0.15) {
        let newIndex = this.activeTestimony + 1;
        if (newIndex > this.testimonies.length - 1) newIndex = 0;
        this.activeTestimony = newIndex;
      }
    },
  },
  data() {
    return {
      testimonies: [],
      activeTestimony: 0,
      originX: 0,
      newX: 0,
    };
  },
  computed: mapState(["language"]),
  watch: {
    language() {
      this.loadContentForCurrentLanguage();
    },
  },
};
</script>

<style scoped lang="scss">
.temoignage-container {
  grid-column: col-start 2 / span 10;
  text-align: center;

  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  .testimony {
    visibility: hidden;
    grid-column-start: 1;
    grid-row-start: 1;

    #vignette {
      height: 11rem;
      width: 11rem;
      border-radius: 50%;
      background-size: cover;
      margin-top: 4rem;
      margin-left: auto;
      margin-right: auto;
    }

    #name {
      font-weight: bold;
      margin-top: 1rem;
      margin-bottom: 0;
    }

    #role {
      margin-top: 0.5rem;
    }
  }

  .active {
    visibility: visible;
  }

  .relative {
    position: relative;

    .testimony-icon {
      position: absolute;
      left: 0;
      top: -4rem;
    }

    .testimony-icon-2 {
      position: absolute;
      transform: rotate(180deg);
      right: 0;
      bottom: -4rem;
    }
  }

  .dot {
    cursor: pointer;
    height: 1rem;
    width: 1rem;
    margin: 0 0.5rem;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;

    &:hover {
      background-color: $orange;
    }
  }

  .activeDot {
    background-color: $orange;
  }
}
</style>
