<template>
  <teleport to="head">
    <title>{{ data.meta_title }}</title>
    <meta name="description" :content="data.meta_description" />
  </teleport>

  <div class="main-grid-container">
    <div class="main-title">
      <h1>{{ data.titre_principal }}</h1>
    </div>
    <div class="main-sub-title">
      <p>{{ data.sous_titre }}</p>
    </div>
    <img
      class="main-image"
      v-if="data.main_image"
      :src="data.main_image.url"
      :srcset="createSrcSet(data.main_image.sizes)"
      :alt="data.main_image_alt"
      sizes="(min-width: 1440px) 1440px, 100vw"
    />
    <div class="main-image-overlay" />
    <div class="titre-produits" id="Produits">
      <h3>{{ data.titre_logiciel }}</h3>
    </div>
  </div>
  <div class="main-grid-container">
    <div class="img-illustr-foodsystem">
      <img
        v-if="data.image_logiciel_1"
        :src="data.image_logiciel_1.url"
        :srcset="createSrcSet(data.image_logiciel_1.sizes)"
        :alt="data.image_logiciel_1_alt"
        sizes="50vw"
      />
    </div>
    <section class="logiciel-FS-container">
      <div class="fs-title-logo">
        <h2>{{ data.nom_logiciel_1 }}</h2>
        <img src="../assets/logos/logo-foodsystem.svg" alt="logo foodsystem" />
      </div>
      <p>{{ data.description_logiciel_1 }}</p>
    </section>
    <div class="fs-product-options-1">
      <ProductOption :label="data.avantage_1_logiciel_1" />
      <ProductOption :label="data.avantage_2_logiciel_1" />
    </div>
    <div class="fs-product-options-2">
      <ProductOption :label="data.avantage_3_logiciel_1" />
      <ProductOption :label="data.avantage_4_logiciel_1" />
    </div>
    <pButton
      class="fs-btn-1"
      :label="data.action_decouvrir_produit"
      v-bind:invert-colors="false"
      @click="navigateTo('foodsystem')"
    />
    <pButton
      class="fs-btn-2"
      :label="data.action_demo"
      v-bind:invert-colors="true"
      @click="openContact(4)"
    />

    <div class="logiciel-spacer"></div>
  </div>

  <div class="main-grid-container">
    <div class="img-illustr-winwin">
      <img
        v-if="data.image_logiciel_2"
        :src="data.image_logiciel_2.url"
        :srcset="createSrcSet(data.image_logiciel_2.sizes)"
        :alt="data.image_logiciel_2_alt"
        sizes="50vw"
      />
    </div>

    <section class="logiciel-WinWin-container">
      <div class="fs-title-logo">
        <h2>{{ data.nom_logiciel_2 }}</h2>
        <img src="../assets/logos/logo-winwin.svg" alt="logo winwin" />
      </div>
      <p>{{ data.description_logiciel_2 }}</p>
    </section>

    <div class="empty-column"></div>

    <div class="ww-product-options-1">
      <ProductOption :label="data.avantage_1_logiciel_2" />
      <ProductOption :label="data.avantage_2_logiciel_2" />
    </div>
    <div class="ww-product-options-2">
      <ProductOption :label="data.avantage_3_logiciel_2" />
      <ProductOption :label="data.avantage_4_logiciel_2" />
    </div>

    <pButton
      class="ww-btn-1"
      :label="data.action_decouvrir_produit"
      v-bind:invert-colors="false"
      @click="navigateTo('winwin')"
    />

    <pButton
      class="ww-btn-2"
      :label="data.action_demo"
      v-bind:invert-colors="true"
      @click="openContact(4)"
    />
  </div>

  <div class="main-grid-container colored-background">
    <section class="entreprise-container">
      <h3>{{ data.description_entreprise }}</h3>
      <pButton
        :label="data.action_decouvrir_entreprise"
        :invert-colors="true"
        @click="navigateTo('entreprise')"
      />
    </section>
  </div>

  <div class="main-grid-container">
    <h2 class="testimonies-title">{{ data.temoignages_client }}</h2>
    <Testimonies id="temoignage" />
    <div class="blog-container">
      <h2 v-if="language !== 'en'" class="center-orange">
        {{ data.dernieres_actualites }}
      </h2>
    </div>

    <BlogIllustratedPreview
      v-for="(post, index) in lastBlogPosts"
      v-bind:key="post.post_title"
      v-bind:title="post.post_title"
      v-bind:image="post.post_illustration_image"
      v-bind:image-alt="post.post_illustration_image_alt"
      v-bind:excerpt="post.post_text ?? '-'"
      v-bind:date="post.date_publication"
      v-bind:show-image="true"
      @click="navigateToBlogPost(index)"
    />

    <p
      v-if="language !== 'en'"
      class="lien-actualite orange"
      @click="navigateTo('blog')"
    >
      {{ data.lien_toutes_actualites }} 🠖
    </p>
  </div>
</template>

<script>
import ProductOption from "../components/atoms/ProductOption";
import pButton from "../components/atoms/pButton";
import BlogIllustratedPreview from "../components/molecules/BlogIllustratedPreview";
import { mapState } from "vuex";
import allMixins from "../assets/mixins/allMixins";
import Testimonies from "../components/molecules/Testimonies";

export default {
  name: "Home",
  components: { Testimonies, BlogIllustratedPreview, ProductOption, pButton },
  mixins: [allMixins],
  created() {
    this.loadContentForCurrentLanguage();
  },
  methods: {
    loadContentForCurrentLanguage() {
      const lang = this.$store.state.language;
      fetch(
        `${this.fetchlink}/wp-json/wp/v2/pages?slug=accueil-${lang}&_fields[]=acf`
      )
        .then((r) => r.json())
        .then((r) => (this.data = r[0].acf));

      fetch(`${this.fetchlink}/wp-json/wp/v2/posts?per_page=3&lang=${lang}`)
        .then((r) => r.json())
        .then((r) => {
          this.lastBlogPosts = r.map((post) => {
            return { slug: post.slug, ...post.acf };
          });
        });
    },
    navigateToBlogPost(index) {
      this.$router.push({
        name: "article",
        params: { lang: this.$store.state.language },
        query: { news: this.lastBlogPosts[index].slug },
      });
    },
  },
  data() {
    return {
      data: [],
      lastBlogPosts: [],
    };
  },
  computed: mapState(["language"]),
  watch: {
    language() {
      this.loadContentForCurrentLanguage();
    },
  },
};
</script>

<style scoped lang="scss">
.main-grid-container {
  margin-top: $header-margin-top;
  @include twelve-col-grid;
}

.main-title {
  grid-column: col-start 3 / span 8;
  text-align: center;
  grid-row: 1;
  align-self: end;

  h1 {
    color: white;
  }
}

.main-sub-title {
  grid-column: col-start 4 / span 6;
  text-align: center;
  grid-row: 2;

  * {
    color: white;
  }

  p {
    margin-top: 0;
  }

  a {
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }
}

@mixin main-image-positioning {
  grid-column: col-start 1 / span 12;
  grid-row: 1 / span 2;
  z-index: -2;
  width: 100vw;
  height: 55vmin;
  object-fit: cover;
  margin-left: $side-img;
}

.main-image {
  @include main-image-positioning;
}

.main-image-overlay {
  @include main-image-positioning;
  background-color: rgba(239, 123, 17, 0.2);
}

.titre-produits {
  grid-column: col-start 3 / span 8;
  text-align: center;
  margin-top: 9vmin;
  margin-bottom: 15vmin;
}

.img-illustr-foodsystem {
  grid-area: span 5 / span 6;
  margin-left: $side-img;

  img {
    @include img-align;
  }
}

.logiciel-FS-container {
  grid-column: col-start 8 / span 4;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  p {
    margin-bottom: 0;
  }
}

.fs-title-logo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1vh;

  img {
    max-width: 20%;
  }
}

.fs-product-options-1 {
  grid-column: col-start 8 / span 2;
  grid-row: 3;

  & > * {
    margin: 2rem 0;
  }
}

.fs-product-options-2 {
  grid-column: col-start 10 / span 2;
  grid-row: 3;

  & > * {
    margin: 2rem 0;
  }
}

.fs-btn-1 {
  grid-column: col-start 8 / span 2;
  grid-row: 4;
}

.fs-btn-2 {
  grid-column: col-start 10 / span 2;
  grid-row: 4;
}

.logiciel-spacer {
  grid-column: col-start 1 / span 12;
  min-height: 7vmin;
}

.img-illustr-winwin {
  grid-column: col-start 7 / span 6;
  grid-row: span 5;
  margin-right: $side-img;

  img {
    @include img-align;
  }
}

.logiciel-WinWin-container {
  grid-column: col-start 2 / span 4;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  p {
    margin-bottom: 0;
  }
}

.ww-product-options-1 {
  grid-column: col-start 2 / span 2;

  & > * {
    margin: 2rem 0;
  }
}

.ww-product-options-2 {
  grid-column: col-start 4 / span 2;

  & > * {
    margin: 2rem 0;
  }
}

.ww-btn-1 {
  grid-column: col-start 2 / span 2;
}
.ww-btn-2 {
  grid-column: col-start 4 / span 2;
}

.colored-background {
  margin-top: 14vmin;
  background-color: #fbdec3;

  .entreprise-container {
    grid-column: col-start 2 / span 10;
    padding: 7rem 10%;
    margin: 6.5rem 0;
    text-align: center;
    background-color: $orange;
    border-radius: 16px;

    h3 {
      color: white;
      margin: 0 auto;
    }

    button {
      margin-top: 3vh;
    }
  }
}

.testimonies-title {
  grid-column: col-start 2 / span 10;
  text-align: center;
  color: $orange;
  margin-bottom: 4rem;
}

.blog-container {
  grid-column: col-start 2 / span 10;
  margin-top: 6vmin;
  margin-bottom: 2vmin;

  .center-orange {
    text-align: center;
    color: $orange;
  }

  .blog-flexbox {
    display: flex;
    justify-content: space-between;
  }
}

.lien-actualite {
  grid-column: col-start 1 / span 12;
  margin: 7.5rem auto 9.5rem auto;
  text-align: center;
  cursor: pointer;
}

@media (max-width: $small-screen) {
  .main-title {
    grid-column: col-start 2 / span 10;
  }
  .main-sub-title {
    grid-column: col-start 3 / span 8;
  }

  .img-illustr-foodsystem {
    grid-area: span 1 / span 12;
  }

  .logiciel-FS-container {
    grid-column: col-start 2 / span 6;

    img {
      max-width: 10vmin;
    }
  }

  .fs-product-options-1,
  .fs-btn-1 {
    grid-column: col-start 2 / span 4;
  }

  .fs-product-options-2,
  .fs-btn-2 {
    grid-column: col-start 6 / span 4;
  }

  .img-illustr-winwin {
    grid-area: span 3 / span 12;
    grid-row: 1;
  }

  .logiciel-WinWin-container {
    grid-column: col-start 2 / span 6;
    grid-row: 2;

    img {
      max-width: 10vmin;
    }
  }

  .ww-product-options-1,
  .ww-btn-1 {
    grid-column: col-start 2 / span 4;
  }
  .ww-product-options-2,
  .ww-btn-2 {
    grid-column: col-start 6 / span 4;
  }
}

@media (max-width: $xs-screen) {
  .main-title {
    margin: auto 0;
  }
  .main-sub-title {
    display: none;
  }
  .main-image,
  .main-image-overlay {
    grid-row: 1 / span 1;
  }

  .logiciel-FS-container {
    grid-column: col-start 1 / span 10;
  }
  .fs-product-options-1,
  .fs-btn-1 {
    grid-column: col-start 1 / span 6;
  }
  .fs-product-options-2,
  .fs-btn-2 {
    grid-column: col-start 7 / span 6;
  }

  .logiciel-WinWin-container {
    grid-column: col-start 1 / span 10;
  }

  .ww-product-options-1,
  .ww-btn-1 {
    grid-column: col-start 1 / span 6;
  }
  .ww-product-options-2,
  .ww-btn-2 {
    grid-column: col-start 7 / span 6;
  }

  .colored-background {
    .entreprise-container {
      grid-column: col-start 1 / span 12;
    }
  }
}
</style>
