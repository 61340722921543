<template>
  <teleport to="head">
    <title>{{ data.meta_title }}</title>
    <meta name="description" :content="data.meta_description" />
  </teleport>

  <div class="main-grid-container" v-if="post">
    <section class="main-post-description">
      <p class="date-main-post p2">{{ post.date_publication }}</p>
      <h3>{{ post.post_title }}</h3>

      <div class="richtext" v-html="post.post_text" />
    </section>

    <img
      class="post-illustration"
      v-if="post.post_illustration_image"
      :src="post.post_illustration_image.url"
      :alt="post.post_illustration_image_alt"
      :srcset="createSrcSet(post.post_illustration_image.sizes)"
      sizes="(max-width: 1440px) 33vw, 480px"
    />
    <p class="titre-actualités orange" @click="navigateTo('blog')">
      {{ data.lien_toutes_actualites }} 🠖
    </p>
  </div>

  <RetourVersLogiciels
    class="retour-logiciels"
    v-if="data.titre_logiciels"
    :titre-logiciel="data.titre_logiciels"
    :description_foodsystem="data.description_foodsystem"
    :description_winwin="data.description_winwin"
    :action_foodsystem="data.action_foodsystem"
    :action_winwin="data.action_winwin"
    :image_logiciels="data.image_logiciels"
    :image_logiciels_alt="data.image_logiciels_alt"
  />
</template>

<script>
import { mapState } from "vuex";
import RetourVersLogiciels from "../components/molecules/RetourVersLogiciels";
import allMixins from "../assets/mixins/allMixins";

export default {
  name: "Article",
  components: { RetourVersLogiciels },
  mixins: [allMixins],
  created() {
    this.loadContentForCurrentLanguage();
    window.addEventListener("resize", this.reRender);
  },
  methods: {
    loadContentForCurrentLanguage() {
      const blogSlug = this.$route.query.news;
      if (!blogSlug || blogSlug === "") {
        this.redirectToAllBlogsPage();
      }

      const blogQueryUrl = `${this.fetchlink}/wp-json/wp/v2/posts?slug=${blogSlug}`;
      fetch(blogQueryUrl)
        .then((r) => r.json())
        .then((r) => {
          // if we have only 1 blog
          if (r.length === 1) {
            this.post = r[0].acf;
          } else {
            this.redirectToAllBlogsPage();
          }
        });

      fetch(
        `${this.fetchlink}/wp-json/wp/v2/pages?slug=blog-${this.$store.state.language}&_fields[]=acf`
      )
        .then((r) => r.json())
        .then((r) => (this.data = r[0].acf));
    },
    reRender() {
      this.$forceUpdate();
    },
    redirectToAllBlogsPage() {
      this.navigateTo("blog");
    },
  },
  data() {
    return {
      post: [],
      data: [],
    };
  },
  computed: {
    ...mapState(["language"]),
  },
  watch: {
    language() {
      this.redirectToAllBlogsPage();
    },
  },
};
</script>

<style scoped lang="scss">
.spacer {
  height: 100vh;
}

.main-grid-container {
  margin-top: calc(#{$header-margin-top} + 3.5vmin);
  @include twelve-col-grid;
}

.main-post-description {
  grid-column: col-start 1 / span 8;
  margin-top: auto;
  margin-bottom: auto;
  p {
    white-space: pre-wrap;
  }

  .richtext {
    ::v-deep * {
      font-size: clamp(1.2rem, 1rem + 0.2083vmax, 2rem); //12, 14@1920, 20
      line-height: clamp(1.2rem, 1rem + 0.729vmax, 3.2rem); //12, 24@1920, 32
    }

    ::v-deep a {
      color: blue;
      text-decoration: underline;
    }
  }
}

.post-illustration {
  grid-column: col-start 9 / span 4;
  max-width: 100%;
  max-height: 50vh;
  object-fit: contain;
  margin: auto;
}

.titre-actualités {
  grid-column: col-start 1 / span 12;
  margin-top: 10vmin;
  margin-bottom: 4vmin;
  text-align: center;
  cursor: pointer;
}

.date-main-post {
  color: $orange;
}

.retour-logiciels {
  margin-top: 14vmin;
  margin-bottom: 21vmin;
}

.separation-line {
  margin: 0 $side-padding;
}

@media (max-width: $small-screen) {
  .main-post-description {
    grid-column: col-start 1 / span 12;
    grid-row: 2;
  }

  .post-illustration {
    grid-column: col-start 2 / span 10;
    grid-row: 1;
    width: 100%;
  }
}
</style>
