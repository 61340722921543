<template>
  <teleport to="head">
    <title>{{ data.meta_title }}</title>
    <meta name="description" :content="data.meta_description" />
  </teleport>

  <div class="img-container">
    <img
      class="main-image-2"
      v-if="data.image_principale"
      :src="data.image_principale.url"
      :srcset="createSrcSet(data.image_principale.sizes)"
      :alt="data.image_principale_alt"
      sizes="100vw"
    />
  </div>
  <div class="main-grid-container">
    <section class="introduction-section">
      <div class="flex">
        <h2>{{ data.titre_logiciel }}</h2>
        <img src="../assets/logos/logo-winwin.svg" alt="logo foodsystem" />
      </div>
      <p>
        {{ data.description_logiciel }}
      </p>
      <p class="orange demo" @click="openContact(4)">{{ data.demo }} 🠖</p>
    </section>
  </div>

  <div class="main-grid-container section-description">
    <ProductOptionStyle2 class="col-3-4" :label="data.avantage_1" />
    <ProductOptionStyle2 class="col-7-5" :label="data.avantage_2" />
    <ProductOptionStyle2 class="col-3-4" :label="data.avantage_3" />
    <ProductOptionStyle2 class="col-7-5" :label="data.avantage_4" />

    <section class="hardware-section">
      <h2>{{ data.titre_hardware }}</h2>
      <p>
        {{ data.description_hardware }}
      </p>
    </section>

    <ProductOptionBold class="col-2-5" :label="data.avantage_hardware_1" />
    <ProductOptionBold class="col-7-5" :label="data.avantage_hardware_2" />
    <ProductOptionBold class="col-2-5" :label="data.avantage_hardware_3" />
    <ProductOptionBold class="col-7-5" :label="data.avantage_hardware_4" />
  </div>
  <section class="price-section">
    <h2>{{ data.titre_prix }}</h2>
    <p>
      {{ data.description_prix }}
    </p>
  </section>

  <div class="main-grid-container">
    <img
      v-if="data.image_illustration_1"
      class="img-1-6"
      :src="data.image_illustration_1.url"
      :srcset="createSrcSet(data.image_illustration_1.sizes)"
      :alt="data.image_illustration_1_alt"
      sizes="(max-width: 1440px) 50vw, 720px"
    />
    <img
      v-if="data.image_illustration_1"
      class="img-7-6"
      :src="data.image_illustration_2.url"
      :srcset="createSrcSet(data.image_illustration_2.sizes)"
      :alt="data.image_illustration_2_alt"
      sizes="(max-width: 1440px) 50vw, 720px"
    />
  </div>
  <RestonsEnContact
    class="restons-contact"
    :titre_newsletter="data.titre_newsletter"
    :description_newsletter="data.description_newsletter"
    :placeholder_email="data.placeholder_email"
    :action_newsletter="data.action_newsletter"
    :image_newsletter="data.image_newsletter"
    :image_newsletter_alt="data.image_newsletter_alt"
    :error="data.error"
    :success="data.success"
    :bad_email="data.bad_email"
  />
</template>
<script>
import ProductOptionStyle2 from "../components/atoms/ProductOptionStyle2";
import ProductOptionBold from "../components/atoms/ProductOptionBold";
import { mapState } from "vuex";
import allMixins from "../assets/mixins/allMixins";
import RestonsEnContact from "../components/molecules/RestonsEnContact";

export default {
  name: "WinWin",
  components: { RestonsEnContact, ProductOptionBold, ProductOptionStyle2 },
  mixins: [allMixins],
  mounted() {
    this.loadContentForCurrentLanguage();
  },
  methods: {
    loadContentForCurrentLanguage() {
      fetch(
        `${this.fetchlink}/wp-json/wp/v2/pages?slug=winwin-${this.$store.state.language}&_fields[]=acf`
      )
        .then((r) => r.json())
        .then((r) => (this.data = r[0].acf));
    },
  },
  data() {
    return {
      data: [],
    };
  },
  computed: mapState(["language"]),
  watch: {
    language() {
      this.loadContentForCurrentLanguage();
    },
  },
};
</script>

<style scoped lang="scss">
.main-grid-container {
  @include twelve-col-grid;
}

.img-container {
  height: 60vmin;
  width: 100%;
  margin-top: $header-margin-top;
}

.main-image-2 {
  height: 60vmin;
  width: 100%;
  object-fit: cover;
}

.introduction-section {
  grid-column: col-start 7 / span 5;

  position: relative;
  top: -50%;

  background-color: white;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.16);
  padding: 7vmin 7vmin;

  .flex {
    display: flex;
    align-items: flex-end;
    img {
      width: 10vh;
      max-width: 10vw;
      margin-left: 5vw;
    }
  }

  .demo {
    cursor: pointer;
  }

  p {
    margin-bottom: 0;
  }
}

.section-description {
  margin-top: -6vh;
}

.col-3-4 {
  grid-column: col-start 3 / span 4;
}

.hardware-section {
  margin-top: 12vmin;
  grid-column: col-start 2 / span 5;

  p {
    margin-bottom: 2.5vmin;
  }
}

.col-7-5 {
  grid-column: col-start 7 / span 5;
}

.col-2-5 {
  grid-column: col-start 2 / span 5;
}

.price-section {
  margin: 22vmin auto 10vmin auto;
  text-align: center;
  max-width: 33vw;

  h2 {
    line-height: clamp(4rem, 1rem + 2.813vmax, 7rem); //24, 48@1920, 50h
  }

  p {
    font-weight: bold;
    padding: 0 2rem;
  }
}

.img-1-6 {
  grid-column: col-start 1 / span 6;
  width: 100%;
  height: 100%;
}

.img-7-6 {
  grid-column: col-start 7 / span 6;
  width: 100%;
  height: 100%;
}

.restons-contact {
  margin-top: 14vmin;
  margin-bottom: 8vmin;
}

@media (max-width: $small-screen) {
  .introduction-section {
    grid-column: col-start 3 / span 10;
  }
  .col-3-4 {
    grid-column: col-start 2 / span 6;
  }

  .col-7-5 {
    grid-column: col-start 8 / span 6;
  }

  .hardware-section {
    grid-column: col-start 2 / span 8;
  }

  .price-section {
    max-width: 50vw;
  }
}

@media (max-width: $xs-screen) {
  .col-2-5,
  .col-3-4,
  .col-7-5 {
    grid-column: col-start 2 / span 10;
  }

  .price-section {
    margin: 22vmin auto 10vmin auto;
    text-align: center;
    max-width: 80vw;
  }

  .img-1-6,
  .img-7-6 {
    grid-column: col-start 1 / span 12;
  }
}
</style>
