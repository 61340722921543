import { createRouter, createWebHistory, RouterView } from "vue-router";
import store from "../store/index";

import Accueil from "../views/Accueil.vue";
import FoodSystem from "../views/FoodSystem";
import WinWin from "../views/WinWin";
import Entreprise from "../views/Entreprise.vue";
import Blog from "../views/Blog";
import Article from "../views/Article";

const routes = [
  {
    // Route taken when no language is specified in url
    path: "/",
    name: "root",
    beforeEnter(to, from, next) {
      next(store.state.language);
    },
  },
  {
    path: "/:lang",
    component: RouterView,
    // will verify that specified language is an accepted language
    beforeEnter(to, from, next) {
      const requestedLanguage = to.params.lang;
      if (store.state.acceptedLanguages.includes(requestedLanguage)) {
        if (store.state.locale !== requestedLanguage) {
          store.commit("changeLanguage", requestedLanguage);
        }
        return next();
      } else {
        return next({ path: store.state.language });
      }
    },
    children: [
      {
        path: "",
        name: "accueil",
        component: Accueil,
      },
      {
        path: "foodsystem",
        name: "foodsystem",
        component: FoodSystem,
      },
      {
        path: "winwin",
        name: "winwin",
        component: WinWin,
      },
      {
        path: "entreprise",
        name: "entreprise",
        component: Entreprise,
      },
      {
        path: "firma",
        name: "firma",
        component: Entreprise,
      },
      {
        path: "blog",
        name: "blog",
        component: Blog,
      },
      {
        path: "article",
        name: "article",
        component: Article,
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "lost",
    component: Accueil,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    const position = {};
    if (to.hash) {
      position.selector = to.hash;
      if (document.querySelector(to.hash)) {
        document.querySelector(to.hash).scrollIntoView();
        return position;
      }
      return false;
    }
    window.scrollTo(0, 0);
  },
});

export default router;
