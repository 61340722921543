<template>
  <teleport to="head">
    <title>{{ data.meta_title }}</title>
    <meta name="description" :content="data.meta_description" />
  </teleport>

  <div class="img-container">
    <img
      class="main-image-2"
      v-if="data.image_principale"
      :src="data.image_principale.url"
      :srcset="createSrcSet(data.image_principale.sizes)"
      :alt="data.image_principale_alt"
      sizes="100vw"
    />
  </div>
  <div class="main-grid-container">
    <section class="introduction-section">
      <div class="flex">
        <h2>{{ data.titre }}</h2>
        <div></div>
        <img src="../assets/logos/logo-foodsystem.svg" alt="logo foodsystem" />
      </div>
      <p>
        {{ data.description }}
      </p>
      <p class="orange demo" @click="openContact(4)">
        {{ data.action_demo }} 🠖
      </p>
    </section>
  </div>

  <div class="main-grid-container section-logiciel">
    <h1 class="logiciel-titre">{{ data.titre_logiciel }}</h1>
    <div class="gestion-commerciale-section">
      <img src="../assets/icons/vu-bold.svg" alt="icon" />
      <h3>{{ data.titre_module_1 }}</h3>
      <p>
        {{ data.description_module_1 }}
      </p>

      <ul v-if="data.avantages_module_1">
        <li
          class="orange"
          v-for="(item, index) in data.avantages_module_1.split(';')"
          v-bind:key="index"
        >
          {{ item }}
        </li>
      </ul>
    </div>
    <div class="gestion-recettes-section">
      <img src="../assets/icons/vu-bold.svg" alt="icon" />
      <h3>{{ data.titre_module_2 }}</h3>
      <p>
        {{ data.description_module_2 }}
      </p>

      <ul v-if="data.avantages_module_2">
        <li
          class="orange"
          v-for="(item, index) in data.avantages_module_2.split(';')"
          v-bind:key="index"
        >
          {{ item }}
        </li>
      </ul>
    </div>
    <div class="gestion-achats-section">
      <img src="../assets/icons/vu-bold.svg" alt="icon" />
      <h3>{{ data.titre_module_3 }}</h3>
      <p>
        {{ data.description_module_3 }}
      </p>

      <ul v-if="data.avantages_module_3">
        <li
          class="orange"
          v-for="(item, index) in data.avantages_module_3.split(';')"
          v-bind:key="index"
        >
          {{ item }}
        </li>
      </ul>
    </div>
    <div class="gestion-etiquetage-section">
      <img src="../assets/icons/vu-bold.svg" alt="icon" />
      <h3>{{ data.titre_module_4 }}</h3>
      <p>
        {{ data.description_module_4 }}
      </p>

      <ul v-if="data.avantages_module_4">
        <li
          class="orange"
          v-for="(item, index) in data.avantages_module_4.split(';')"
          v-bind:key="index"
        >
          {{ item }}
        </li>
      </ul>
    </div>
    <pButton
      class="demo-btn"
      :label="data.action_demo"
      v-bind:invert-colors="false"
      @click="openContact(4)"
    />
  </div>

  <div class="main-grid-container colored-background">
    <section class="entreprise-section">
      <h3>
        {{ data.description_entreprise }}
      </h3>

      <pButton
        :label="data.action_entreprise"
        :invert-colors="true"
        @click="navigateTo('entreprise')"
      />
    </section>
  </div>

  <div class="main-grid-container avantages-section">
    <section class="avantages">
      <h2>{{ data.titre_avantages }}</h2>
      <div class="avantages-flex">
        <div>
          <ProductOption :label="data.avantage_1" />
          <ProductOption :label="data.avantage_2" />
        </div>
        <div>
          <ProductOption :label="data.avantage_3" />
          <ProductOption :label="data.avantage_4" />
        </div>
      </div>
    </section>
    <img
      v-if="data.image_avantages"
      class="illustration-avantages"
      :src="data.image_avantages.url"
      :srcset="createSrcSet(data.image_avantages.sizes)"
      :alt="data.image_avantages_alt"
      sizes="(max-width: 1440px) 58vw, 840px"
    />
  </div>
</template>

<script>
import ProductOption from "../components/atoms/ProductOption";
import PButton from "../components/atoms/pButton";
import { mapState } from "vuex";
import allMixins from "../assets/mixins/allMixins";

export default {
  name: "FoodSystem",
  components: { PButton, ProductOption },
  mixins: [allMixins],
  created() {
    this.loadContentForCurrentLanguage();
  },
  methods: {
    loadContentForCurrentLanguage() {
      fetch(
        `${this.fetchlink}/wp-json/wp/v2/pages?slug=foodsystem-${this.$store.state.language}&_fields[]=acf`
      )
        .then((r) => r.json())
        .then((r) => (this.data = r[0].acf));
    },
    navigateTo(destination) {
      this.$router.push({
        name: destination,
        params: { lang: this.$store.state.language },
      });
    },
  },
  data() {
    return {
      data: [],
    };
  },
  computed: mapState(["language"]),
  watch: {
    language() {
      this.loadContentForCurrentLanguage();
    },
  },
};
</script>

<style scoped lang="scss">
.img-container {
  height: 60vmin;
  width: 100%;
  margin-top: $header-margin-top;
}

.main-image-2 {
  height: 60vmin;
  width: 100%;
  object-fit: cover;
}

.main-grid-container {
  @include twelve-col-grid;
}

.introduction-section {
  grid-column: col-start 7 / span 5;

  position: relative;
  top: -50%;

  background-color: white;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.16);
  padding: 7vmin 7vmin;

  .flex {
    display: flex;
    align-items: flex-end;

    img {
      width: 10vh;
      max-width: 10vw;
      margin-left: 4vw;
    }
  }

  .demo {
    cursor: pointer;
  }

  p {
    margin-bottom: 0;
  }
}

.section-logiciel {
  margin-top: -12vh;

  .logiciel-titre {
    grid-column: col-start 2 / span 6;
    margin-bottom: 3vmin;
  }

  .gestion-commerciale-section {
    grid-column: col-start 2 / span 4;
  }

  .gestion-recettes-section {
    grid-column: col-start 6 / span 4;
  }

  .gestion-achats-section {
    grid-column: col-start 2 / span 4;
  }

  .gestion-etiquetage-section {
    grid-column: col-start 6 / span 4;
  }

  img {
    margin-bottom: 2.5vmin;
  }

  ul {
    padding-left: 1.6rem;
    padding-top: 1.6rem;
  }

  .demo-btn {
    margin-top: 5vmin;
    grid-column: col-start 2 / span 2;
  }
}

.colored-background {
  margin-top: 19vmin;
  background-color: #fbdec3;

  .entreprise-section {
    grid-column: col-start 2 / span 10;
    text-align: center;
    padding: 7rem 10%;
    margin: 6.5rem 0;
    background-color: $orange;
    border-radius: 16px;

    h3 {
      margin-left: auto;
      margin-right: auto;
      color: white;
    }

    button {
      margin-top: 3vh;
    }
  }
}

.avantages-section {
  margin-top: 11vmin;
  margin-bottom: 10vmin;
}

.avantages {
  grid-column: col-start 2 / span 4;
  margin: auto 0;

  .avantages-12 {
    display: flex;
    flex-direction: column;
  }

  .avantages-34 {
    display: flex;
    flex-direction: column;
  }

  //Class in ProductOption
  .container {
    margin: 1rem 0;
  }

  h2 {
    margin-bottom: 4vmin;
  }
}

.illustration-avantages {
  grid-column: col-start 6 / span 7;
  @include img-align;
}

@media (max-width: $small-screen) {
  .introduction-section {
    grid-column: col-start 3 / span 10;
  }
  .section-logiciel {
    .logiciel-titre {
      grid-column: col-start 2 / span 11;
      margin-top: 5vh;
      margin-bottom: 3vmin;
    }

    .gestion-commerciale-section {
      grid-column: col-start 2 / span 10;
    }

    .gestion-recettes-section {
      grid-column: col-start 2 / span 10;
    }

    .gestion-achats-section {
      grid-column: col-start 2 / span 10;
    }

    .gestion-etiquetage-section {
      grid-column: col-start 2 / span 10;
    }

    .demo-btn {
      grid-column: col-start 2 / span 4;
    }
  }

  .avantages {
    grid-column: col-start 2 / span 10;
    margin: auto 0;

    .avantages-flex {
      display: flex;

      div:first-child {
        margin-right: 3rem;
      }
    }
  }

  .illustration-avantages {
    grid-column: col-start 2 / span 10;
  }
}

@media (max-width: $xs-screen) {
  .section-logiciel {
    .demo-btn {
      grid-column: col-start 2 / span 6;
    }
  }

  .avantages {
    .avantages-flex {
      display: block;
    }
  }

  .colored-background {
    .entreprise-section {
      grid-column: col-start 1 / span 12;
    }
  }
}
</style>
