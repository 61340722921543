<template>
  <teleport to="head">
    <title>{{ data.meta_title }}</title>
    <meta name="description" :content="data.meta_description" />
  </teleport>

  <div class="main-grid-container" v-if="posts[0]">
    <h2 v-if="language === 'en'" class="language-warning orange">
      {{ data.avertissement_langue }}
    </h2>
    <router-link
      :to="{
        name: 'article',
        params: { lang: $store.state.language },
        query: { news: posts[0].slug },
      }"
      class="main-post-description"
    >
      <p class="date-main-post p2">{{ posts[0].date_publication }}</p>
      <h3>{{ posts[0].post_title }}</h3>
      <p class="p2 richtext" v-html="postMainTextPreview" />
    </router-link>
    <img
      class="main-post-illustration"
      v-if="posts[0].post_illustration_image"
      :src="posts[0].post_illustration_image.url"
      :alt="posts[0].post_illustration_image_alt"
      :srcset="createSrcSet(posts[0].post_illustration_image.sizes)"
      @click="showBlogPost(0)"
      sizes="(max-width: 1440px) 58vw, 840px"
    />

    <h3 class="titre-actualités orange">{{ data.titre }}</h3>

    <router-link
      :to="{
        name: 'article',
        params: { lang: $store.state.language },
        query: { news: posts[index + 1].slug },
      }"
      class="span-4-blog"
      v-for="(post, index) in posts.slice(1, posts.length)"
      v-bind:key="post.post_title"
      :class="{ extramargin: index > 2, phonemargin: index > 0 }"
    >
      <BlogIllustratedPreview
        v-bind:title="post.post_title"
        v-bind:image="post.post_illustration_image"
        v-bind:image-alt="post.post_illustration_image_alt"
        v-bind:excerpt="post.post_text ?? '-'"
        v-bind:date="post.date_publication"
        v-bind:show-image="postImageShouldShow(index)"
      />

      <hr v-if="lineShouldShow(index)" class="line-wide" />
    </router-link>
  </div>

  <RetourVersLogiciels
    class="retour-logiciels"
    v-if="data.titre_logiciels"
    :titre-logiciel="data.titre_logiciels"
    :description_foodsystem="data.description_foodsystem"
    :description_winwin="data.description_winwin"
    :action_foodsystem="data.action_foodsystem"
    :action_winwin="data.action_winwin"
    :image_logiciels="data.image_logiciels"
    :image_logiciels_alt="data.image_logiciels_alt"
  />
</template>

<script>
import { mapState } from "vuex";
import BlogIllustratedPreview from "../components/molecules/BlogIllustratedPreview";
import RetourVersLogiciels from "../components/molecules/RetourVersLogiciels";
import allMixins from "../assets/mixins/allMixins";

export default {
  name: "Blog",
  components: { RetourVersLogiciels, BlogIllustratedPreview },
  mixins: [allMixins],
  created() {
    this.loadContentForCurrentLanguage();
    window.addEventListener("resize", this.reRender);
  },
  methods: {
    loadContentForCurrentLanguage() {
      const blogsFetchUrl =
        `${this.fetchlink}/wp-json/wp/v2/posts?lang=` +
        (this.language !== "en" ? this.language : "fr");
      fetch(blogsFetchUrl)
        .then((r) => r.json())
        .then((r) => {
          try {
            this.posts = r.map((post) => {
              return { slug: post.slug, ...post.acf };
            });
          } catch (e) {
            // ignore loading errors
          }
        });

      fetch(
        `${this.fetchlink}/wp-json/wp/v2/pages?slug=blog-${this.$store.state.language}&_fields[]=acf`
      )
        .then((r) => r.json())
        .then((r) => (this.data = r[0].acf));
    },
    showBlogPost(index) {
      this.$router.push({
        name: "article",
        params: { lang: this.$store.state.language },
        query: { news: this.posts[index].slug },
      });
    },
    reRender() {
      this.$forceUpdate();
    },
    postImageShouldShow(currentIndex) {
      if (window.innerWidth > 960) {
        return currentIndex <= 2;
      } else if (window.innerWidth > 600) {
        return currentIndex <= 1;
      } else {
        return true;
      }
    },
    lineShouldShow(currentIndex) {
      if (window.innerWidth > 960) {
        return currentIndex % 3 === 0;
      } else if (window.innerWidth > 600) {
        return currentIndex % 2 === 0;
      } else {
        return true;
      }
    },
    striphtml(inputString) {
      return inputString.replace(/<\/?[^>]+>/gi, " ");
    },
  },
  data() {
    return {
      posts: [],
      data: [],
    };
  },
  computed: {
    ...mapState(["language"]),
    postMainTextPreview() {
      try {
        return (
          this.posts[0].post_text
            .replace(/<\/?[^>]+>/gi, " ")
            .substring(0, 250) +
          (this.posts[0].post_text.length > 250 ? "..." : "")
        );
      } catch {
        return "";
      }
    },
  },
  watch: {
    language() {
      this.loadContentForCurrentLanguage();
    },
  },
};
</script>

<style scoped lang="scss">
.spacer {
  height: 100vh;
}

.main-grid-container {
  margin-top: calc(#{$header-margin-top} + 3.5vmin);
  @include twelve-col-grid;
}

.language-warning {
  grid-column: col-start 1 / span 12;
  margin-bottom: 5vmin;
}

.main-post-description {
  grid-column: col-start 1 / span 4;
  padding-top: 4vmin;
  cursor: pointer;

  .richtext {
    ::v-deep * {
      font-size: clamp(1.2rem, 1rem + 0.2083vmax, 2rem); //12, 14@1920, 20
      line-height: clamp(1.2rem, 1rem + 0.729vmax, 3.2rem); //12, 24@1920, 32
    }
  }
}

.main-post-illustration {
  grid-column: col-start 5 / span 7;
  height: 100%;
  max-width: 100%;
  max-height: 60vh;
  object-fit: cover;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}

.line {
  grid-column: col-start 1 / span 12;
  width: 100%;
}

.line-wide {
  margin-top: 4vmin;
  width: calc(100vw - 2 * #{$side-padding});
  position: absolute;
  left: $side-padding;
}

.extramargin {
  margin-top: 4vmin;
}

.titre-actualités {
  grid-column: col-start 1 / span 10;
  margin-top: 0;
  margin-bottom: 2vmin;
}

.date-main-post {
  color: $orange;
}

.span-4-blog {
  grid-area: span 1 / span 4;
  overflow: hidden;
}

.retour-logiciels {
  margin-top: 14vmin;
  margin-bottom: 21vmin;
}

@media (max-width: $small-screen) {
  .main-post-description {
    grid-column: col-start 1 / span 5;
  }
  .main-post-illustration {
    grid-column: col-start 6 / span 7;
    height: 35vmax;
  }
  .span-4-blog {
    grid-area: span 1 / span 6;
  }
}

@media (max-width: $xs-screen) {
  .main-post-description {
    grid-column: col-start 1 / span 12;
    grid-row: 2;
    padding-top: 0;

    .date-main-post {
      margin-top: 0;
    }
  }
  .main-post-illustration {
    grid-column: col-start 1 / span 12;
    grid-row: 1;
  }

  .span-4-blog {
    grid-area: span 1 / span 12;
  }

  .phonemargin {
    margin-top: 5vmin;
  }
}
</style>
