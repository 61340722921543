<template>
  <section class="blog-preview">
    <img
      :src="image.url"
      v-if="showImage && image"
      :srcset="createSrcSet(image.sizes)"
      :alt="imageAlt"
      sizes="(max-width: 1440px) 33vw, 480px"
    />
    <h3>{{ title }}</h3>
    <p class="p2 richtext" v-html="previewText" />
    <p class="p3">{{ date }}</p>
  </section>
</template>

<script>
import allMixins from "../../assets/mixins/allMixins";

export default {
  name: "BlogPreview",
  mixins: [allMixins],
  props: {
    image: Object,
    title: String,
    imageAlt: String,
    excerpt: String,
    date: String,
    showImage: Boolean,
  },
  computed: {
    previewText: function () {
      return (
        // removes htmls value (e.g: <p>)
        this.excerpt.replace(/<\/?[^>]+>/gi, " ").substring(0, 250) +
        (this.excerpt.length > 250 ? "..." : "")
      );
    },
  },
};
</script>

<style scoped lang="scss">
.blog-preview {
  grid-area: span 1 / span 4;

  h3 {
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
  }

  .richtext {
    ::v-deep * {
      font-size: clamp(1.2rem, 1rem + 0.2083vmax, 2rem); //12, 14@1920, 20
      line-height: clamp(1.2rem, 1rem + 0.729vmax, 3.2rem); //12, 24@1920, 32
    }
  }

  &:hover {
    cursor: pointer;
  }
}

img {
  height: 20vw;
  width: 100%;
  object-fit: cover;
}

.p3 {
  color: grey;
}

@media (max-width: $small-screen) {
  .blog-preview {
    grid-area: span 1 / span 6;
  }
  img {
    height: 30vw;
  }
}

@media (max-width: $xs-screen) {
  .blog-preview {
    grid-area: span 1 / span 12;
  }
  img {
    height: 33vh;
  }
}
</style>
