<template>
  <div class="header-flexbox">
    <router-link :to="{ name: 'accueil', params: { lang: currentLanguage } }">
      <img src="../assets/logos/panipro.svg" alt="logo panipro" />
    </router-link>
    <section class="header-links">
      <div class="dropdown">
        <p class="cp-text header-link down-arrow">
          {{ data.produits }}
        </p>
        <div class="dropdown-content-container">
          <div class="dropdown-content">
            <span class="cp-text">FoodSystem</span>
            <div
              class="content-background pointer"
              @click="navigateTo('foodsystem')"
            />
          </div>
          <div class="dropdown-content">
            <span class="cp-text">WinWin</span>
            <div
              class="content-background pointer"
              @click="navigateTo('winwin')"
            />
          </div>
        </div>
      </div>
      <p
        class="header-link cp-text underline"
        @click="navigateTo('entreprise')"
      >
        {{ data.entreprise }}
      </p>
      <p class="header-link cp-text underline" @click="navigateTo('blog')">
        {{ data.blog }}
      </p>
      <div class="dropdown header-link">
        <p class="cp-text down-arrow">
          {{ languageMap.get(currentLanguage) }}
        </p>
        <div class="dropdown-content-container">
          <div
            class="dropdown-content"
            v-for="language in acceptedLanguages.filter(
              (lang) => lang !== currentLanguage
            )"
            :key="language"
          >
            <span class="cp-text">{{ languageMap.get(language) }}</span>
            <div class="content-background" @click="changeLanguage(language)" />
          </div>
        </div>
      </div>
      <pButton
        :label="data.contact"
        :invert-colors="false"
        class="cp-text"
        ref="supportbtn"
        id="supportbtn"
        @click="openContact(1)"
      />
    </section>
    <FAB
      class="FAB"
      :class="{ activeFab: showFAB }"
      :title="data.contact"
      @click="openContact(1)"
    />
    <img
      class="hamburger-button"
      src="../assets/icons/hamburger.svg"
      alt="icon menu"
      @click="showWholePageMenu = true"
    />
    <div class="whole-page-menu" :class="{ activemenu: showWholePageMenu }">
      <img
        src="../assets/icons/close-small.svg"
        alt="close icon"
        @click="showWholePageMenu = false"
      />
      <div class="whole-flex">
        <h2 @click="navigateAndCloseMenu('foodsystem')">FoodSystem</h2>
        <h2 @click="navigateAndCloseMenu('winwin')">WinWin</h2>
        <h2 @click="navigateAndCloseMenu('entreprise')">
          {{ data.entreprise }}
        </h2>
        <h2 @click="navigateAndCloseMenu('blog')">{{ data.blog }}</h2>
        <h2 @click="openContactAndCloseMenu">{{ data.contact }}</h2>

        <div class="lang-flex">
          <h3
            class="underlinelang"
            v-for="language in acceptedLanguages"
            :key="language"
            :class="{ underlinelangactive: language === currentLanguage }"
            @click="changeLanguage(language)"
          >
            {{ language.toUpperCase() }}
          </h3>
        </div>
      </div>
    </div>
  </div>
  <hr />
</template>

<script>
import pButton from "./atoms/pButton";
import allMixins from "../assets/mixins/allMixins";
import FAB from "./atoms/FAB";

export default {
  name: "Header",
  mixins: [allMixins],
  props: {
    data: Object,
  },
  components: { FAB, pButton },
  created() {
    window.addEventListener("scroll", this.handleScroll, { passive: true });
    this.showFAB = window.innerWidth < 960;
  },
  mounted() {
    this.supportBtn = document.querySelector("#supportbtn");
  },
  methods: {
    changeLanguage(newLanguage) {
      this.$store.commit("changeLanguage", newLanguage);
      this.$router.replace({ params: { lang: newLanguage } }).catch(() => {});
      this.showWholePageMenu = false;
    },
    handleScroll() {
      const elem = this.supportBtn.getBoundingClientRect();
      this.showFAB =
        (elem.bottom < 0 || window.innerWidth < 960) && !this.contactShowing;
    },
    navigateAndCloseMenu(dest) {
      this.navigateTo(dest);
      this.showWholePageMenu = false;
    },
    openContactAndCloseMenu() {
      this.openContact(1);
      this.showWholePageMenu = false;
    },
  },
  data() {
    return {
      languageMap: new Map([
        ["fr", "Français"],
        ["de", "Deutsch"],
        ["it", "Italiano"],
        ["en", "English"],
      ]),
      supportBtn: {},
      showFAB: false,
      showWholePageMenu: false,
    };
  },
  computed: {
    currentLanguage() {
      return this.$store.state.language;
    },
    acceptedLanguages() {
      return this.$store.state.acceptedLanguages;
    },
    contactShowing() {
      return this.$store.state.showContact;
    },
  },
  watch: {
    showWholePageMenu() {
      document.querySelector("body").style.overflowY = this.showWholePageMenu
        ? "hidden"
        : "visible";
    },
    contactShowing() {
      this.handleScroll();
    },
  },
};
</script>

<style scoped lang="scss">
img {
  height: 75px;
}

.header-flexbox {
  padding: 0 $side-padding;
  margin: 3rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header-links {
    display: flex;
    align-items: center;
  }

  .header-link {
    margin: 0 2rem;
    padding: 10px 0; //distance to orange border underline
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    font-weight: 600;
    cursor: pointer;
  }

  .underline:hover {
    border-bottom: 3px solid $orange;
  }
}

.pointer {
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;

  .down-arrow {
    font-weight: 600;

    &:after {
      margin-left: 2rem;
      content: "▼";
    }
  }

  .dropdown-content-container {
    transition: 0.5s;
    visibility: hidden;
    opacity: 0;
    width: 120%;
    border-top: 1px solid black;
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.16);

    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    z-index: 1;

    .dropdown-content {
      position: relative;
      padding: 1rem;

      .content-background {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: transparent;

        &:hover {
          background: #ef7b11;
          opacity: 0.25;
        }
      }
    }
  }

  &:hover .dropdown-content-container {
    visibility: visible;
    opacity: 1;
  }
}

#supportbtn {
  min-width: 8vw;
  margin-left: 2rem;
}

.FAB {
  opacity: 0;
  position: fixed;
  bottom: 6vh;
  right: 1vw;
  transform: translateX(120%);
  transition: 0.5s ease-in-out;
}

.activeFab {
  opacity: 1;
  transform: translateX(0);
  z-index: 4;
}

.whole-page-menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  //display: none;
  z-index: 5;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s;

  img {
    position: absolute;
    right: 1vw;
    top: 1vw;
    width: 3.2rem;
    height: 3.2rem;
  }

  .whole-flex {
    margin-top: 18vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;

    h2,
    h3 {
      margin: 2vh 1rem;
      cursor: pointer;
    }

    h2 {
      color: $orange;
    }

    .lang-flex {
      margin-top: 7vh;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .underlinelang {
      border-bottom: 2px solid transparent;
    }
    .underlinelangactive {
      border-bottom: 2px solid $orange;
    }
  }
}

.activemenu {
  display: block;
  visibility: visible;
  opacity: 1;
  transition: all 0.5s;
}

.hamburger-button {
  display: none;
  height: 2rem;
}

hr {
  border: none;
  height: 1px;
  background-color: #e4e4e4;
}

@media (max-width: $small-screen) {
  #supportbtn {
    display: none;
  }
  .header-flexbox {
    .header-link {
      margin: 0 1rem;
    }
  }

  .dropdown .down-arrow:after {
    margin-left: 1rem;
  }
}

@media (max-width: $xs-screen) {
  .hamburger-button {
    display: block;
  }

  .links,
  .header-links {
    display: none !important;
  }
}
</style>
