<template>
  <button
    class="panipro-button"
    :class="{ inverted: invertColors, hide: hideBtn }"
  >
    {{ label }}
  </button>
</template>

<script>
export default {
  name: "pButton",
  props: {
    label: String,
    invertColors: Boolean,
    hideBtn: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.panipro-button {
  background: $orange;
  color: white;
  border: 1px solid $orange;
  border-radius: 6px;
  padding: 1.5rem;
  transition: 0.5s;

  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
}

.inverted {
  background: white;
  color: $orange;
}

.hide {
  visibility: hidden;
  transition: 0s;
}
</style>
