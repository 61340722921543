export default {
  methods: {
    createSrcSet(sizesObjetc) {
      const requiredSizes = [
        "2048x2048",
        "1536x1536",
        "large",
        "medium_large",
        "medium",
      ];
      let returnSrcSet = "";
      for (let i = 0; i < requiredSizes.length; i++) {
        const currentSize = requiredSizes[i];
        if (sizesObjetc[currentSize]) {
          const widthName = `${currentSize}-width`;
          returnSrcSet += `${sizesObjetc[currentSize]} ${sizesObjetc[widthName]}w,`;
        }
      }
      return returnSrcSet.length > 0 ? returnSrcSet.slice(0, -1) : "";
    },
    openContact(step) {
      this.$store.commit("showContact", { show: true, startStep: step });
    },
    navigateTo(destination, hashDestination = null) {
      this.$router.push({
        name: destination,
        params: { lang: this.$store.state.language },
        hash: hashDestination,
      });
    },
  },
};
