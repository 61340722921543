<template>
  <button>
    <img src="../../assets/icons/support.svg" alt="" />
    <p v-if="title" class="p2">{{ title.toUpperCase() }}</p>
  </button>
</template>

<script>
export default {
  name: "FAB",
  props: {
    title: String,
  },
};
</script>

<style scoped lang="scss">
button {
  background: $orange;
  color: white;
  border: 1px solid $orange;
  border-radius: 100px;
  padding: 0.2rem 2rem;
  transition: 0.5s;
  box-shadow: $boxshadow;
  z-index: 100;

  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }

  p {
    color: white;
    margin-left: 1rem;
  }

  display: flex;
  align-items: center;
}
</style>
