<template>
  <div class="container2">
    <img src="../../assets/icons/up.svg" alt="checked icon" />
    <h3>{{ label }}</h3>
  </div>
</template>

<script>
export default {
  name: "ProductOptionStyle2",
  props: {
    label: String,
  },
};
</script>

<style scoped lang="scss">
.container2 {
  display: flex;
  justify-items: center;
  align-items: center;

  h3 {
    margin-left: 2rem;
  }
}
</style>
