<template>
  <div class="main-grid-container">
    <h3 class="titre-logiciels">
      {{ titreLogiciel }}
    </h3>

    <div class="logiciel-span-6">
      <img src="../../assets/logos/logo-foodsystem.svg" alt="logo foodsystem" />
      <p>
        {{ description_foodsystem }}
      </p>
    </div>
    <p class="orange link" @click="navigateTo('foodsystem')">
      {{ action_foodsystem }}&nbsp;🠖
    </p>

    <div class="logiciel-span-6">
      <img src="../../assets/logos/logo-winwin.svg" alt="logo winwin" />
      <p>
        {{ description_winwin }}
      </p>
    </div>

    <p class="orange link" @click="navigateTo('winwin')">
      {{ action_winwin }}&nbsp;🠖
    </p>

    <div class="img-illustr-logiciels">
      <img
        v-if="image_logiciels"
        :src="image_logiciels.url"
        :alt="image_logiciels_alt"
        :srcset="createSrcSet(image_logiciels.sizes)"
        sizes="50vw"
      />
    </div>
  </div>
</template>

<script>
import allMixins from "../../assets/mixins/allMixins";

export default {
  name: "RetourVersLogiciels",
  mixins: [allMixins],
  props: {
    titreLogiciel: String,
    description_foodsystem: String,
    action_foodsystem: String,
    description_winwin: String,
    action_winwin: String,
    image_logiciels: Object,
    image_logiciels_alt: String,
  },
};
</script>

<style scoped lang="scss">
.main-grid-container {
  @include twelve-col-grid;
}

.titre-logiciels {
  grid-column: col-start 1 / span 4;
  grid-row-start: 1;
  margin-bottom: 1.5vmin;
}

.logiciel-span-6 {
  grid-column: span 3;
  grid-row-start: 2;
  display: flex;
  flex-direction: column;

  img {
    width: 6.4rem;
    height: 6.4rem;
  }

  p {
    margin-top: 4vmin;
    margin-bottom: 1.5vmin;
  }
}

.link {
  grid-row: 3;
  grid-column: span 3;
  cursor: pointer;
  margin-top: 0;
}

.img-illustr-logiciels {
  grid-area: span 4 / span 6;
  margin-right: $side-img;
  max-height: 60vh;

  img {
    @include img-align;
  }
}

@media (max-width: $small-screen) {
  .img-illustr-logiciels {
    grid-column: col-start 2 / span 10;
    grid-row: 1;
    margin-right: 0;
    max-height: none;
  }

  .titre-logiciels {
    grid-column: col-start 2 / span 8;
    grid-row: 2;
  }

  .logiciel-span-6 {
    &:first-of-type {
      grid-column: col-start 2 / span 4;
    }
    grid-column: span 4;
    grid-row: 3;
  }

  .link {
    &:first-of-type {
      grid-column: col-start 2 / span 4;
    }
    grid-column: span 4;
    grid-row: 4;
  }
}

@media (max-width: $xs-screen) {
  .img-illustr-logiciels {
    grid-column: col-start 1 / span 12;
    grid-row: 1;
  }

  .titre-logiciels {
    grid-column: col-start 1 / span 12;
  }

  .logiciel-span-6 {
    &:first-of-type {
      grid-column: col-start 1 / span 6;
    }
    grid-column: span 6;
    grid-row: 3;
  }

  .link {
    &:first-of-type {
      grid-column: col-start 1 / span 6;
    }
    grid-column: span 6;
    grid-row: 4;
  }
}
</style>
