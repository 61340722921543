<template>
  <div class="main-grid-container">
    <div class="img-illustr-newsletter">
      <img
        v-if="image_newsletter"
        :src="image_newsletter.url"
        :alt="image_newsletter_alt"
        :srcset="createSrcSet(image_newsletter.sizes)"
        sizes="(max-width: 1440px) 50vw, 720px"
      />
    </div>

    <section class="newsletter-section">
      <h3>{{ titre_newsletter }}</h3>
      <p>
        {{ description_newsletter }}
      </p>
      <div class="form">
        <input
          class="p2"
          v-model="email"
          type="text"
          name="email"
          :placeholder="placeholder_email"
          autocomplete="off"
        />
        <div class="button-and-spinner">
          <p-button
            id="action-btn"
            :label="action_newsletter"
            :invert-colors="false"
            :hide-btn="showSpinner"
            @click="sendNewsletter"
          />
          <spinner :show="showSpinner" />
        </div>
      </div>
      <div class="labels">
        <p class="p2 error-label" v-bind:class="{ hide: !showEmailError }">
          {{ bad_email }}
        </p>
        <p class="p2 success-label" v-bind:class="{ hide: !showEmailSent }">
          {{ success }}
        </p>
        <p class="p2 error-label" v-bind:class="{ hide: !showEmailNotSent }">
          {{ error }}
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import PButton from "../atoms/pButton";
import Spinner from "../atoms/Spinner";
import allMixins from "../../assets/mixins/allMixins";

export default {
  name: "RestonsEnContact",
  components: { Spinner, PButton },
  mixins: [allMixins],
  props: {
    image_newsletter: Object,
    image_newsletter_alt: String,
    titre_newsletter: String,
    description_newsletter: String,
    placeholder_email: String,
    action_newsletter: String,
    bad_email: String,
    error: String,
    success: String,
  },
  methods: {
    sendNewsletter() {
      this.showEmailError = false;
      this.showEmailSent = false;
      this.showEmailNotSent = false;

      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const emailIsValid = emailRegex.test(this.email);
      this.showEmailError = !emailIsValid;

      if (emailIsValid) {
        this.showSpinner = true;
        fetch(`${this.fetchlink}/wp-json/v1/newsletter`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: this.email,
            language: this.$store.state.language,
          }),
        })
          .then((r) => {
            this.showSpinner = false;
            if (r.status === 200) {
              this.showEmailSent = true;
              this.email = "";
            } else {
              this.showEmailNotSent = true;
            }
          })
          .catch(() => {
            this.showSpinner = false;
            this.showEmailNotSent = true;
          });
      }
    },
  },
  data() {
    return {
      data: [],
      showEmailError: false,
      showEmailSent: false,
      showEmailNotSent: false,
      showSpinner: false,
      showActionButton: true,
      email: null,
    };
  },
};
</script>

<style scoped lang="scss">
.img-illustr-newsletter {
  grid-area: span 1 / span 6;

  img {
    @include img-align;
  }
}

.newsletter-section {
  grid-column: col-start 8 / span 5;
  margin: auto 0;

  h3 {
    margin-bottom: 4vmin;
  }

  .form {
    display: flex;
    margin-top: 4vmin;
  }

  input {
    flex-grow: 2;
    margin-right: 2vw;

    @include input-field;

    margin-top: 0;
  }

  .hide {
    visibility: hidden;
  }

  .button-and-spinner {
    position: relative;

    button {
      height: min-content;
      align-self: flex-end;
    }

    div {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -1.5rem; //half element width
      margin-top: -1.5rem;
    }
  }

  .labels {
    position: relative;

    .error-label {
      position: absolute;
      top: 0;
      color: red;
    }

    .success-label {
      position: absolute;
      top: 0;
      color: green;
    }
  }

  .hiddenbtn {
    visibility: hidden;
  }
}

@media (max-width: $small-screen) {
  .img-illustr-newsletter {
    grid-column: col-start 2 / span 10;
  }

  .newsletter-section {
    grid-column: col-start 2 / span 10;
  }
}

@media (max-width: $xs-screen) {
  .img-illustr-newsletter {
    grid-column: col-start 1 / span 12;
  }

  .newsletter-section {
    grid-column: col-start 1 / span 12;
  }
}
</style>
